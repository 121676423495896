import { useMemo } from 'react';
import { useRouter } from 'next/router';
import includes from 'lodash/includes';
import mapValues from 'lodash/mapValues';
import map from 'lodash/map';

import { CompactWorkspaceSecondaryMenuLinksConfig } from './useCompactWorkspaceSecondaryMenu.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { SearchFilterNanoID } from '../../../../../../searchFilters/searchFiltersTypes';

import { useCompactWorkspaceSecondaryMenuTotals } from '../useCompactWorkspaceSecondaryMenuTotals';

import { CommonPermissions } from '../../../../../commonConstants';
import { MessagePath } from '../../../../../../messages/MessagePath';
import { ProjectPath } from '../../../../../../projects/ProjectPath';
import { TaskPath } from '../../../../../../tasks/TaskPath';

import { getWorkspaceActiveMenu } from '../../utils/getWorkspaceActiveMenu';

import {
  messagesKeys,
  projectMemberInvitesKeys,
  projectsKeys,
  taskMemberInvitesKeys,
  tasksKeys
} from '../../../../../../../locales/keys';

function useCompactWorkspaceSecondaryMenu() {
  const {
    pathname,
    query: { slug }
  } = useRouter();

  const {
    // myTasksTotalCount,
    // tasksResultsTotalCount,
    tasksInvitationsTotalCount,
    projectsInvitationsTotalCount,
    unreadMessagesTotalCount,
    onlyClientsMessagesTotalCount
    // tasksWithoutSourceFilesTotalCount
  } = useCompactWorkspaceSecondaryMenuTotals();

  const isMyTasks = includes(pathname, TaskPath.compactMyTasks());

  const isMyProjects = includes(pathname, ProjectPath.compactMyProjects());

  const isTasksInvitations = includes(pathname, TaskPath.compactInvitations());

  const isProjectsInvitations = includes(
    pathname,
    ProjectPath.compactInvitations()
  );

  const isFilteredItems = includes(pathname, '/filter');

  const isWithoutSourceFilesTasks = includes(
    pathname,
    TaskPath.compactWithoutSourceFiles()
  );

  const isAllTasks =
    (includes(pathname, TaskPath.compactIndex()) &&
      !isMyTasks &&
      !isTasksInvitations &&
      !isFilteredItems) ||
    isWithoutSourceFilesTasks;

  const isAllProjects =
    includes(pathname, ProjectPath.compactIndex()) &&
    !isMyProjects &&
    !isProjectsInvitations &&
    !isFilteredItems;

  const config = useMemo<CompactWorkspaceSecondaryMenuLinksConfig>(
    () => ({
      tasks: [
        {
          action: CommonPermissions.READ_COMPACT_WORKSPACE_MENU_ALL_TASKS_LINK,
          active: isAllTasks,
          href: TaskPath.compactIndex(),
          i18nText: tasksKeys.all,
          icon: IconsEnum.VIEW_LIST_SOLID
        },
        {
          action: CommonPermissions.READ_COMPACT_WORKSPACE_MENU_TASKS_LINK,
          active: isMyTasks,
          // count: myTasksTotalCount,
          href: TaskPath.compactMyTasks(),
          i18nText: tasksKeys.my,
          icon: IconsEnum.VIEW_LIST_USER_SOLID
        },
        {
          action:
            CommonPermissions.READ_COMPACT_WORKSPACE_MENU_INVITATIONS_LINK,
          active: includes(pathname, TaskPath.compactInvitations()),
          count: tasksInvitationsTotalCount,
          href: TaskPath.compactInvitations(),
          i18nText: taskMemberInvitesKeys.plural,
          icon: IconsEnum.TICKET_SOLID
        }
        // {
        //   action:
        //     CommonPermissions.READ_COMPACT_WORKSPACE_MENU_TASKS_RESULTS_LINK,
        //   active: includes(pathname, TaskPath.compactResults()),
        //   count: tasksResultsTotalCount,
        //   href: TaskPath.compactResults(),
        //   i18nText: resultsKeys.plural,
        //   icon: IconsEnum.ROCKET_SOLID
        // },
        // {
        //   action:
        //     CommonPermissions.READ_COMPACT_WORKSPACE_MENU_TASKS_WITHOUT_SOURCE_FILES_LINK,
        //   active: includes(pathname, TaskPath.compactWithoutSourceFiles()),
        //   // count: tasksWithoutSourceFilesTotalCount,
        //   href: TaskPath.compactWithoutSourceFiles(),
        //   i18nText: tasksKeys.withoutSourceFiles,
        //   icon: IconsEnum.DOCUMENT_ARCHIVE_SOLID
        // },
        // {
        //   action: CommonPermissions.READ_COMPACT_WORKSPACE_MENU_TIMELINE_LINK,
        //   active: includes(pathname, TaskPath.compactTimeline()),
        //   href: TaskPath.compactTimeline(),
        //   i18nText: tasksKeys.timeline,
        //   icon: IconsEnum.CALENDAR_SOLID
        // },
        // {
        //   action: CommonPermissions.READ_COMPACT_WORKSPACE_MENU_FINANCE_LINK,
        //   active: includes(pathname, TaskPath.compactFinance()),
        //   href: TaskPath.compactFinance(),
        //   i18nText: words.finance,
        //   icon: IconsEnum.CURRENCY_DOLLAR_SOLID
        // },
        // {
        //   action: CommonPermissions.READ_COMPACT_WORKSPACE_MENU_NOTES_LINK,
        //   active: includes(pathname, TaskPath.compactNotes()),
        //   href: TaskPath.compactNotes(),
        //   i18nText: formsFields.notes,
        //   icon: IconsEnum.ANNOTATION_SOLID
        // }
      ],
      projects: [
        {
          action:
            CommonPermissions.READ_COMPACT_WORKSPACE_MENU_ALL_PROJECTS_LINK,
          active: isAllProjects,
          href: ProjectPath.compactIndex(),
          i18nText: projectsKeys.all,
          icon: IconsEnum.VIEW_LIST_SOLID
        },
        {
          action: CommonPermissions.READ_COMPACT_WORKSPACE_MENU_PROJECTS_LINK,
          active: isMyProjects,
          // count: myProjectsTotalCount,
          href: ProjectPath.compactMyProjects(),
          i18nText: projectsKeys.my,
          icon: IconsEnum.VIEW_LIST_USER_SOLID
        },
        {
          action:
            CommonPermissions.READ_COMPACT_WORKSPACE_MENU_PROJECTS_INVITATIONS_LINK,
          active: includes(pathname, ProjectPath.compactInvitations()),
          count: projectsInvitationsTotalCount,
          href: ProjectPath.compactInvitations(),
          i18nText: projectMemberInvitesKeys.plural,
          icon: IconsEnum.TICKET_SOLID
        }
      ],
      messages: [
        {
          action:
            CommonPermissions.READ_COMPACT_WORKSPACE_MENU_ALL_MESSAGES_LINK,
          active: pathname === MessagePath.compactIndex(),
          href: MessagePath.compactIndex(),
          i18nText: messagesKeys.all,
          icon: IconsEnum.VIEW_LIST_SOLID
        },
        {
          action: CommonPermissions.READ_COMPACT_WORKSPACE_MENU_UNREAD_LINK,
          active: includes(pathname, MessagePath.compactUnread()),
          count: unreadMessagesTotalCount,
          href: MessagePath.compactUnread(),
          i18nText: messagesKeys.new,
          icon: IconsEnum.BADGE_CHECK
        },
        {
          action:
            CommonPermissions.READ_COMPACT_WORKSPACE_MENU_MY_MESSAGES_LINK,
          active: includes(pathname, MessagePath.compactMyMessages()),
          href: MessagePath.compactMyMessages(),
          i18nText: messagesKeys.my,
          icon: IconsEnum.PAPER_AIRPLANE_SOLID
        },
        {
          action:
            CommonPermissions.READ_COMPACT_WORKSPACE_MENU_ONLY_CLIENTS_LINK,
          active: includes(pathname, MessagePath.compactOnlyClients()),
          count: onlyClientsMessagesTotalCount,
          href: MessagePath.compactOnlyClients(),
          i18nText: messagesKeys.fromClients,
          icon: IconsEnum.USER_GROUP_SOLID
        },
        {
          action:
            CommonPermissions.READ_COMPACT_WORKSPACE_MENU_VISIBLE_FOR_CLIENTS_LINK,
          active: includes(pathname, MessagePath.compactVisible()),
          href: MessagePath.compactVisible(),
          i18nText: messagesKeys.visible,
          icon: IconsEnum.EYE_SOLID
        }
      ]
    }),
    [
      isAllProjects,
      isAllTasks,
      isMyProjects,
      isMyTasks,
      onlyClientsMessagesTotalCount,
      pathname,
      projectsInvitationsTotalCount,
      tasksInvitationsTotalCount,
      unreadMessagesTotalCount
    ]
  );

  const activeMenu = getWorkspaceActiveMenu(pathname);

  return {
    activeMenu,
    activeFilterNanoId: slug as SearchFilterNanoID,
    config,
    permissions: mapValues(config, (item) => map(item, 'action'))
  };
}

export default useCompactWorkspaceSecondaryMenu;
