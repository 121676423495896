import { ProjectNanoID, ProjectUUID } from '../projects/projectsTypes';
import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { TaskID, TaskNanoID, TaskStatuses, TaskUserID } from './tasksTypes';
import { UserGroupID, UserNanoID } from '../users/usersTypes';
import { TeamNanoID } from '../teams/teamsTypes';

export class TaskCache {
  static indexCacheKey() {
    return 'tasks';
  }

  static compactIndexCacheKey() {
    return 'tasks-compact';
  }

  static compactTimelineCacheKey() {
    return 'tasks-compact-timeline';
  }

  static compactFinanceCacheKey() {
    return 'tasks-compact-finance';
  }

  static compactNotesCacheKey() {
    return 'tasks-compact-notes';
  }

  static projectTasksCacheKey(projectNanoId: ProjectNanoID) {
    return `tasks-project-${projectNanoId}`;
  }

  static compactProjectTasksCacheKey(projectNanoId: ProjectNanoID) {
    return `tasks-compact-project-${projectNanoId}`;
  }

  static compactProjectTimelineCacheKey(projectNanoId: ProjectNanoID) {
    return `${this.compactProjectTasksCacheKey(projectNanoId)}-timeline`;
  }

  static compactProjectFinanceCacheKey(projectNanoId: ProjectNanoID) {
    return `${this.compactProjectTasksCacheKey(projectNanoId)}-finance`;
  }

  static compactProjectNotesCacheKey(projectNanoId: ProjectNanoID) {
    return `${this.compactProjectTasksCacheKey(projectNanoId)}-notes`;
  }

  static compactProjectResultsCacheKey(projectNanoId: ProjectNanoID) {
    return `${this.compactProjectTasksCacheKey(projectNanoId)}-results`;
  }

  static compactProjectWithoutSourceFilesCacheKey(
    projectNanoId: ProjectNanoID
  ) {
    return `${this.compactProjectTasksCacheKey(
      projectNanoId
    )}-without-source-files`;
  }

  static filteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `tasks-filtered-${searchFilterNanoId}`;
  }

  static compactFilteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `tasks-compact-filtered-${searchFilterNanoId}`;
  }

  static burningCacheKey() {
    return 'tasks-burning';
  }

  static withoutSourceFiles() {
    return 'tasks-without-source-files';
  }

  static compactWithoutSourceFiles() {
    return 'tasks-compact-without-source-files';
  }

  static compactUserWithoutSourceFiles(userNanoId: UserNanoID) {
    return `tasks-compact-user-${userNanoId}-without-source-files`;
  }

  static dashboardWithoutSourceFiles() {
    return 'tasks-dashboard-without-source-files';
  }

  static current() {
    return 'tasks-current';
  }

  static totalCountCacheKey() {
    return 'tasks-total-count';
  }

  static indexByStatusCacheKey(status: TaskStatuses) {
    return `tasks-by-${status}`;
  }

  static reportsByStatusCacheKey(status: TaskStatuses) {
    return `tasks-reports-by-${status}`;
  }

  static statusTotalCountCacheKey(status: TaskStatuses) {
    return `tasks-total-count-${status}`;
  }

  static burningTotalCountCacheKey() {
    return 'tasks-burning-total-count';
  }

  static myTotalCountCacheKey() {
    return 'tasks-my-total-count';
  }

  static withoutSourceFilesTotalCountCacheKey() {
    return 'tasks-without-source-files-total-count';
  }

  static favoritesTotalCountCacheKey() {
    return 'tasks-favorites-total-count';
  }

  static resultsTotalCountCacheKey() {
    return 'tasks-results-total-count';
  }

  static hiddenTasksCountCacheKey() {
    return 'hidden-tasks-count';
  }

  static canceledTasksCountCacheKey() {
    return 'canceled-tasks-count';
  }

  static doneTasksCountCacheKey() {
    return 'done-tasks-count';
  }

  static favoriteCacheKey() {
    return 'tasks-favorite';
  }

  static myTasksCacheKey() {
    return 'my-tasks';
  }

  static companyTasksCacheKey(companyNanoId: TeamNanoID) {
    return `tasks-company-${companyNanoId}`;
  }

  static companyTasksFavoriteCacheKey(companyNanoId: TeamNanoID) {
    return `tasks-favorite-company-${companyNanoId}`;
  }

  static companyTasksInProgressCacheKey(companyNanoId: TeamNanoID) {
    return `tasks-in-progress-company-${companyNanoId}`;
  }

  static companyTasksBurningCacheKey(companyNanoId: TeamNanoID) {
    return `tasks-burning-company-${companyNanoId}`;
  }

  static companyTasksResultsCacheKey(companyNanoId: TeamNanoID) {
    return `tasks-results-company-${companyNanoId}`;
  }

  static userTasksCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}`;
  }

  static compactUserTasksCacheKey(userNanoId: UserNanoID) {
    return `tasks-compact-user-${userNanoId}`;
  }

  static userActiveTasksCacheKey() {
    return 'user-active-tasks';
  }

  static userQualityControlTasksCacheKey() {
    return 'user-quality-control-tasks';
  }

  static userProfileTasksCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-profile`;
  }

  static itemsResultsCacheKey() {
    return 'tasks-results';
  }

  static userItemsResultsCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-results`;
  }

  static filteredResultsCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `tasks-filtered-results-${searchFilterNanoId}`;
  }

  static compactFilteredResultsCacheKey(
    searchFilterNanoId: SearchFilterNanoID
  ) {
    return `tasks-compact-filtered-results-${searchFilterNanoId}`;
  }

  static showCacheKey() {
    return 'task';
  }

  static showFinCacheKey() {
    return 'task-fin';
  }

  static showRedirectCacheKey(taskId: TaskID) {
    return `project-${taskId}-redirect`;
  }

  static showBreadcrumbsCacheKey() {
    return 'task-breadcrumbs';
  }

  static userResultsCacheKey() {
    return 'tasks-user-results';
  }

  static userUnderpaymentCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-underpayment`;
  }

  static userDebtCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-debt`;
  }

  static userBadDebtCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-bad-debt`;
  }

  static userBadDebtAmountCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-bad-debt-amount`;
  }

  static selectByProjectFieldCacheKey(projectUuid: ProjectUUID) {
    return `select-by-project-${projectUuid}-field-tasks`;
  }

  static selectFieldCacheKey(name: string) {
    return `select-${name}-field-tasks`;
  }

  static selectFieldSelectedCacheKey() {
    return `select-field-tasks-selected`;
  }

  static activeTaskCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-active-task`;
  }

  static messagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-messages`;
  }

  static versionMessagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-version-messages`;
  }

  static firstMessagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-fist-messages`;
  }

  static lastMessagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-last-messages`;
  }

  static lastMessagesWithResultCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-last-messages-with-result`;
  }

  static pinnedMessagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-pinned-messages`;
  }

  static favoriteMessagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-favorite-messages`;
  }

  static flaggedMessagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-flagged-messages`;
  }

  static forwardMessageCacheKey(projectUuid: ProjectUUID) {
    return `tasks-${projectUuid}-forward-messages`;
  }

  static taskMembersCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-task-members`;
  }

  static taskNotificationsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-task-notifications`;
  }

  static sidebarFilesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-sidebar-files`;
  }

  static sidebarMediaCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-sidebar-media`;
  }

  static filesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-files`;
  }

  static fileAttachmentsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-file-attachments`;
  }

  static imagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-images`;
  }

  static resultsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-results`;
  }

  static resultsWithoutVersionCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-results-without-version`;
  }

  static resultsCountCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-results-count`;
  }

  static sourceFilesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-source-files`;
  }

  static selectedProductsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-selected-products`;
  }

  static selectedLifestylesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-selected-lifestyles`;
  }

  static selectedMaterialsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-selected-materials`;
  }

  static transactionsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-transactions`;
  }

  static billsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-bills`;
  }

  static showQueryKey(taskNanoId: TaskNanoID) {
    return ['task', taskNanoId];
  }

  static transactionsCountCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-transactions-count`;
  }

  static userTasksCountCacheKey(userId: TaskUserID) {
    return `user-${userId}-tasks-count`;
  }

  static userResultsCountCacheKey(userId: TaskUserID) {
    return `user-${userId}-results-count`;
  }

  static importTasksToProject() {
    return 'import-tasks-to-project';
  }

  static taskMembersInvitesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-members-invites`;
  }

  static taskMembersInvitesCountCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-members-invites-count`;
  }

  static companyUserTasksCacheKey(
    companyNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `tasks-company-${companyNanoId}-user-${userNanoId}`;
  }

  static companyUserTasksResultsCacheKey(
    companyNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `tasks-results-company-${companyNanoId}-user-${userNanoId}`;
  }

  static compactTeamTasksResultsCacheKey(teamNanoId: TeamNanoID) {
    return `tasks-compact-results-team-${teamNanoId}`;
  }

  static companyUserTasksBurningCacheKey(
    companyNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `tasks-burning-company-${companyNanoId}-user-${userNanoId}`;
  }

  static companyUserTasksFavoriteCacheKey(
    companyNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `tasks-favorite-company-${companyNanoId}-user-${userNanoId}`;
  }

  static companyUserProjectsCacheKey(
    companyNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `projects-company-${companyNanoId}-user-${userNanoId}`;
  }

  static tasksPriceCacheKey(taskNanoIds: TaskNanoID[]) {
    return `tasks-${taskNanoIds}-price`;
  }

  static tasksItemsCacheKey(taskNanoIds: TaskNanoID[]) {
    return `tasks-${taskNanoIds}-items`;
  }

  static userGroupCacheKey(userGroupId: UserGroupID) {
    return `tasks-user-group-${userGroupId}`;
  }

  static userGroupFinanceCacheKey(userGroupId: UserGroupID) {
    return `tasks-user-group-${userGroupId}-finance`;
  }

  static userGroupNotesCacheKey(userGroupId: UserGroupID) {
    return `tasks-user-group-${userGroupId}-notes`;
  }

  static userGroupResultsCacheKey(userGroupId: UserGroupID) {
    return `tasks-user-group-${userGroupId}-results`;
  }

  static userGroupTimelineCacheKey(userGroupId: UserGroupID) {
    return `tasks-user-group-${userGroupId}-timeline`;
  }

  static userGroupWithoutSourceFilesCacheKey(userGroupId: UserGroupID) {
    return `tasks-user-group-${userGroupId}-without-source-files`;
  }

  static indexUserGroupCacheKey() {
    return 'tasks-user-groups';
  }

  static indexUserGroupFinanceCacheKey() {
    return 'tasks-user-groups-finance';
  }

  static indexUserGroupNotesCacheKey() {
    return 'tasks-user-groups-notes';
  }

  static indexUserGroupResultsCacheKey() {
    return 'tasks-user-groups-results';
  }

  static indexUserGroupTimelineCacheKey() {
    return 'tasks-user-groups-timeline';
  }

  static indexUserGroupWithoutSourceFilesCacheKey() {
    return 'tasks-user-groups-without-source-files';
  }
}
