import { request } from 'graphql-request';

import { GRAPHQL_API_URI } from '../../../../../../../config';

import { FetchItemGqlQuery, NanoID, UUID } from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

interface FetchItemProps {
  query: FetchItemGqlQuery;
  uuid: UUID | NanoID;
}

export function fetchItem({ query, uuid }: FetchItemProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole('log', 'fetchItem', uuid, rawName);

  const requestId = generateUuid();

  return request(
    `${GRAPHQL_API_URI}?queryName=${name}&requestId=${requestId}`,
    query,
    { uuid },
    { requestId }
  );
}
