import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { PageNanoID } from '../../../pagesTypes';

import {
  FETCH_PAGE_SHOW_PAGE_QUERY,
  FetchPageShowPageQueryResponse
} from '../../../queries/fetchPageShowPage.query';

import { usePageShowPage } from '../../../hooks/usePageShowPage';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';

import { PageCache } from '../../../PageCache';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

interface ShowPageModalButtonProps {
  className?: ClassName;
  i18nCancelText?: I18nText;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  i18nTitle: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  pageNanoId: PageNanoID;
  title?: string;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

function ShowPageModalButton({
  className,
  i18nCancelText,
  i18nText,
  i18nTextClassName,
  i18nTitle,
  icon,
  iconClassName,
  pageNanoId,
  title,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton
}: ShowPageModalButtonProps) {
  const { page, pageLoading, togglePageData, pageError } =
    usePageShowPage<FetchPageShowPageQueryResponse>({
      uuid: pageNanoId,
      query: FETCH_PAGE_SHOW_PAGE_QUERY,
      cacheKey: PageCache.showPageCacheKey(pageNanoId)
    });

  return (
    <SimpleModalButton
      className={
        className ||
        'py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0'
      }
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      i18nCancelText={i18nCancelText}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      i18nTitle={i18nTitle}
      icon={icon || IconsEnum.INFORMATION_CIRCLE_SOLID}
      iconClassName={iconClassName || 'h-5 w-5'}
      isLoading={pageLoading}
      onOpen={togglePageData}
      title={title}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      tooltipSingleton={tooltipSingleton}
      withCloseButton
      withoutCancelButton
      withoutInitialFocus
      withoutSubmitButton
    >
      <LoadingSkeleton loaded={!pageLoading}>
        <AlertMessage message={pageError} />
        <div
          className="p-4"
          dangerouslySetInnerHTML={{
            __html: page?.localizedBody
          }}
        />
      </LoadingSkeleton>
    </SimpleModalButton>
  );
}

export default ShowPageModalButton;
