export enum CommonPermissions {
  READ_COMPACT_WORKSPACE_MENU_PROJECTS_RESULTS_LINK = 'read_compact_workspace_menu_projects_results_link',
  READ_WORKERS_FULL_NAME_FILTER = 'read_workers_full_name_filter',
  READ_CLIENTS_FULL_NAME_FILTER = 'read_clients_full_name_filter',
  READ_TASKS_AND_PROJECTS_MENU_TASKS_LINK = 'read_tasks_and_projects_menu_tasks_link',
  READ_TASKS_AND_PROJECTS_MENU_PROJECTS_LINK = 'read_tasks_and_projects_menu_projects_link',
  READ_TASKS_AND_PROJECTS_MENU_MESSAGES_LINK = 'read_tasks_and_projects_menu_messages_link',
  READ_ATTACHMENTS_LIST_DATE_FIELD = 'read_attachments_list_date_field',
  READ_ATTACHMENTS_LIST_SIZE_FIELD = 'read_attachments_list_size_field',
  READ_ATTACHMENTS_LIST_USER_FIELD = 'read_attachments_list_user_field',
  READ_FOLDERS_LIST_FILE_COUNT_FIELD = 'read_folders_list_file_count_field',
  READ_FOLDERS_LIST_DATE_FIELD = 'read_folders_list_date_field',
  READ_FOLDERS_LIST_USER_FIELD = 'read_folders_list_user_field',
  READ_WORKSPACE_MENU_CREATE_PROJECT_IN_TEAM_LINK = 'read_workspace_menu_create_project_in_team_link',
  READ_COMPACT_WORKSPACE_MENU = 'read_compact_workspace_menu',
  READ_COMPACT_WORKSPACE_MENU_ALL_TASKS_LINK = 'read_compact_workspace_menu_all_tasks_link',
  READ_COMPACT_WORKSPACE_MENU_TASKS_LINK = 'read_compact_workspace_menu_tasks_link',
  READ_COMPACT_WORKSPACE_MENU_USER_GROUP_TASKS_LINK = 'read_compact_workspace_menu_user_group_tasks_link',
  READ_COMPACT_WORKSPACE_MENU_INVITATIONS_LINK = 'read_compact_workspace_menu_invitations_link',
  READ_COMPACT_WORKSPACE_MENU_TASKS_RESULTS_LINK = 'read_compact_workspace_menu_tasks_results_link',
  READ_COMPACT_WORKSPACE_MENU_TASKS_WITHOUT_SOURCE_FILES_LINK = 'read_compact_workspace_menu_tasks_without_source_files_link',
  READ_COMPACT_WORKSPACE_MENU_PROJECTS_LINK = 'read_compact_workspace_menu_projects_link',
  READ_COMPACT_WORKSPACE_MENU_ALL_PROJECTS_LINK = 'read_compact_workspace_menu_all_projects_link',
  READ_COMPACT_WORKSPACE_MENU_FINANCE_LINK = 'read_compact_workspace_menu_finance_link',
  READ_COMPACT_WORKSPACE_MENU_TIMELINE_LINK = 'read_compact_workspace_menu_timeline_link',
  READ_COMPACT_WORKSPACE_MENU_NOTES_LINK = 'read_compact_workspace_menu_notes_link',
  READ_COMPACT_WORKSPACE_MENU_ALL_MESSAGES_LINK = 'read_compact_workspace_menu_all_messages_link',
  READ_COMPACT_WORKSPACE_MENU_UNREAD_LINK = 'read_compact_workspace_menu_unread_link',
  READ_COMPACT_WORKSPACE_MENU_MY_MESSAGES_LINK = 'read_compact_workspace_menu_my_messages_link',
  READ_COMPACT_WORKSPACE_MENU_ONLY_CLIENTS_LINK = 'read_compact_workspace_menu_only_clients_link',
  READ_COMPACT_WORKSPACE_MENU_VISIBLE_FOR_CLIENTS_LINK = 'read_compact_workspace_menu_visible_for_clients_link',
  READ_COMPACT_WORKSPACE_MENU_TASKS_SEARCH_FILTERS = 'read_compact_workspace_menu_tasks_search_filters',
  READ_COMPACT_WORKSPACE_MENU_PROJECTS_SEARCH_FILTERS = 'read_compact_workspace_menu_projects_search_filters',
  READ_COMPACT_WORKSPACE_MENU_MESSAGES_SEARCH_FILTERS = 'read_compact_workspace_menu_messages_search_filters',
  READ_COMPACT_WORKSPACE_MENU_PROJECTS_INVITATIONS_LINK = 'read_compact_workspace_menu_projects_invitations_link',
  READ_COMPACT_PAYMENTS_MENU = 'read_compact_payments_menu',
  READ_COMPACT_COMPANY_PAYMENTS_MENU = 'read_compact_company_payments_menu',
  READ_FALLBACK_S3_UPLOAD = 'read_fallback_s3_upload'
}
