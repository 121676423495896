export default {
  addToDescription: 'Add to description',
  addToRecords: 'Add to records',
  addFlag: 'Add flag',
  addStar: 'Add star',
  all: 'All messages',
  archive: 'Archive',
  areYouSureYouWantToSendThisMessageToAllProjectTasks:
    'Are you sure you want to send this message to all project tasks?',
  areYouSureYouWantToMarkAllMessagesAsReadThisActionIsIrreversible:
    'Are you sure you want to mark all messages as read? This action is irreversible.',
  authors: 'Message authors',
  automaticallyPinMessageWithTodoItem:
    'Automatically pin message with todo item',
  betweenMessagesAtLeast15MinutesApart:
    'Between messages at least 15 minutes apart',
  cancelReply: 'Cancel reply',
  casualToneOfVoiceTranslation: 'Casual tone of voice translation',
  chatGpt: 'ChatGPT',
  chatGptDescription: 'Message ChatGPT',
  checkGrammar: 'Check grammar',
  correctGrammarWithChatGpt: 'Correct grammar with ChatGPT',
  colorsSwathes: 'Color swatches',
  createAMessageThanksALot: "Create a message: 'Thanks a lot'",
  createATodoListFromAPdf: 'Create a to-do list from a PDF',
  criticalRequirements: 'Critical requirements',
  displayTime: 'Display time',
  downloadAllAttachments: 'Download all attachments',
  emailNotificationSent: 'Email notification sent',
  flagged: 'Flagged',
  flaggedMessages: 'Flagged messages',
  forward: 'Forward message',
  forwardMessageFrom: 'Forward message from',
  forwardedFrom: 'Forwarded from',
  friendlyToneOfVoiceTranslation: 'Friendly tone of voice translation',
  fromClients: 'From clients',
  fileAttachments: 'File attachments',
  filterByAttachmentType: 'Filter by attachment type',
  filterByMessageType: 'Filter by message type',
  financeActivity: 'Finance activity',
  hideFromClient: 'Hide from client',
  inProjectsWhereIAmAMember: 'In projects where I am a member',
  inTasksWhereIAmAMember: 'In tasks where I am a member',
  jumpTo: 'Jump to',
  markAllAsRead: 'Mark all as read',
  markAsRead: 'Mark as read',
  markAsRelevant: 'Mark as relevant',
  markAsUnread: 'Mark as unread',
  markAsUnrelevant: 'Mark as unrelevant',
  materials: 'Materials',
  messageId: 'Message ID',
  messageIds: 'Message IDs',
  messagesFilter: 'Messages filter',
  messageWithAttachments: 'Message with attachments',
  my: 'My messages',
  new: 'New',
  nextToEachMessage: 'Next to each message',
  noPlural: 'No messages',
  noAttachments: 'No attachments',
  passwordWasSuccessfullyUpdated: 'Password was successfully updated.',
  pin: 'Pin',
  pinMessage: 'Pin message',
  pinnedMessages: 'Pinned messages',
  plural: 'Messages',
  project: 'Project messages',
  products: 'Products',
  read: 'Read',
  removeCommand: 'Remove command',
  removeFlag: 'Remove flag',
  removeStar: 'Remove star',
  restore: 'Restore',
  reply: 'Reply',
  replyTo: 'Reply to',
  runCommand: 'Run command',
  lifestyles: 'Lifestyles',
  search: 'Search messages',
  selectAuthor: 'Select author',
  send: 'Send message',
  sendEmailNotification: 'Send email notification',
  sending: 'Sending',
  sent: 'Message sent',
  settings: 'Messages settings',
  setHiddenForClient: 'Set hidden for client',
  setVisibleForClient: 'Set visible for client',
  showOlder: 'Show older messages',
  showWhiteboardMessages: 'Show whiteboard messages',
  showTimeStampForEveryMessage: 'Show a time stamp for every message',
  showToClient: 'Show to client',
  singular: 'Message',
  starred: 'Starred',
  starredMessages: 'Starred messages',
  thanksALot: 'Thanks a lot',
  toLatestMessages: 'To latest messages',
  todoLists: 'Todo lists',
  tracked: 'tracked',
  translateIntoEnglish: 'Translate into English',
  translateTheTextAndMakeItAppropriateForSendingToTheClient:
    'Translate the text and make it appropriate for sending to the client',
  translateTheTextIntoEnglishInACasualTone:
    'Translate the text into English in a casual tone',
  translateTheTextIntoEnglishInAFriendlyTone:
    'Translate the text into English in a friendly tone',
  timeOfEachIndividualMessageWillBeAvailableOnHover:
    'Time of each individual message will be available on hover',
  todoListFromPdf: 'To-do list from PDF',
  unpin: 'Unpin',
  unpinMessage: 'Unpin message',
  unread: 'Unread',
  userMessages: 'User messages',
  visible: 'Visible',
  withAttachments: 'With attachments',
  whiteboardNotifications: 'Whiteboard notifications'
};
