import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';

import {
  FetchCategoriesGqlQuery,
  FetchCategoriesCacheKey,
  FetchCategoriesFilters,
  FetchCategoriesSort,
  FetchCategoriesPage,
  FetchCategoriesLimit
} from '../../categoriesTypes';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_CATEGORIES_FILTERS,
  INITIAL_CATEGORIES_LIMIT,
  INITIAL_CATEGORIES_PAGE,
  INITIAL_CATEGORIES_SORT
} from '../../categoriesConstants';

interface CategoriesDefaultOptions {
  cacheKey: FetchCategoriesCacheKey;
  query: FetchCategoriesGqlQuery;
  initialFilters?: FetchCategoriesFilters;
  initialSort?: FetchCategoriesSort;
  initialPage?: FetchCategoriesPage;
  initialLimit?: FetchCategoriesLimit;
  options?: {
    cacheTime?: number;
    withoutPrefetch?: boolean;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

interface CategoriesWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface CategoriesWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type CategoriesOptions = CategoriesDefaultOptions &
  (CategoriesWithPrefetchItemOptions | CategoriesWithoutPrefetchItemOptions);

const scope = 'categories';

function usePaginatedCategories<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_CATEGORIES_FILTERS,
  initialSort = INITIAL_CATEGORIES_SORT,
  initialPage = INITIAL_CATEGORIES_PAGE,
  initialLimit = INITIAL_CATEGORIES_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: CategoriesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    prefetchItem
  } = useIndexQuery<ItemType>({
    scope,
    query,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options
  });

  return {
    categoriesData: data,
    categories: items,
    categoriesError: itemsError,
    categoriesTotalCount: itemsTotalCount,
    categoriesFetched: isFetched,
    categoriesLoading: isLoading,
    categoriesIsPlaceholderData: isPlaceholderData,
    categoriesFilters: currentFilters,
    categoriesSort: currentSort,
    categoriesPage: currentPage,
    categoriesLimit: currentLimit,
    filterCategories: filterItems,
    changeCategoriesFilters: changeItemsFilters,
    clearCategoriesFilters: clearItemsFilters,
    sortCategories: sortItems,
    paginateCategories: paginateItems,
    limitCategories: limitItems,
    prefetchCategories: prefetchItems,
    prefetchCategory: prefetchItem
  };
}

export default usePaginatedCategories;
