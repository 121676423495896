import { InvoiceID, InvoiceNanoID, InvoiceUUID } from './invoicesTypes';

const baseUrl = 'invoices';

export class InvoiceBffUrl {
  static index() {
    return baseUrl;
  }

  static create() {
    return baseUrl;
  }

  static createFromProforma() {
    return `${baseUrl}/create_from_proforma`;
  }

  static pay(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/pay`;
  }

  static void(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/void`;
  }

  static chargeback(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/chargeback`;
  }

  static cancel(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/cancel`;
  }

  static cancelSmartContracts(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/cancel_smart_contracts`;
  }

  static uncollectible(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/uncollectible`;
  }

  static send(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/send`;
  }

  static show(invoiceUuid: InvoiceUUID | InvoiceNanoID | InvoiceID) {
    return `${baseUrl}/${invoiceUuid}`;
  }

  static payFromDeposit(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/pay_from_deposit`;
  }

  static download(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/download`;
  }

  static downloadPdf(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/download_pdf`;
  }

  static receipt(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/receipt`;
  }

  static receiptPdf(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/receipt_pdf`;
  }

  static sendToEmail(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/send_to_email`;
  }

  static contractorAllocate(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/contractor_allocate`;
  }

  static contractorPayBde(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/contractor_pay_bde`;
  }

  static changeCurrency(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/change_currency`;
  }

  static payInternal(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/pay_internal`;
  }

  static updateBillingInfo(invoiceId: InvoiceID) {
    return `${baseUrl}/${invoiceId}/update_billing_info`;
  }
}
