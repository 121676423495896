import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { ClientError } from 'graphql-request';

import { fetchItem } from '../../../common/hooks/base/reactQuery/baseActions/fetchItem';

import {
  FetchPageCacheKey,
  FetchPageGqlQuery,
  PageNanoID,
  PageUUID
} from '../../pagesTypes';

import { parseRequestError } from '../../../../utils/parseRequestError';
import { IsLoading } from '../../../../types';

type ShowQueryErrorType = Error | ClientError;

interface PageResponse<ItemType> {
  page: ItemType | null;
}

interface PageOptions {
  uuid: PageUUID | PageNanoID;
  query: FetchPageGqlQuery;
  cacheKey: FetchPageCacheKey;
  initFetch?: boolean;
}

const itemKey = 'page';

function usePageShowPage<ItemType>({
  uuid,
  query,
  cacheKey,
  initFetch = false
}: PageOptions) {
  const [pageEnabled, setPageEnabled] = useState<boolean>(initFetch);

  const { data, isLoading, error } = useQuery<
    PageResponse<ItemType>,
    ShowQueryErrorType
  >(
    cacheKey,
    async () =>
      await fetchItem({
        query,
        uuid
      }),
    { enabled: pageEnabled }
  );

  const togglePageData = useCallback<() => void>(
    () => setPageEnabled((prevState) => !prevState),
    [setPageEnabled]
  );

  return {
    page: data?.[itemKey],
    pageLoading: isLoading as IsLoading,
    pageError: parseRequestError(error),
    togglePageData
  };
}

export default usePageShowPage;
