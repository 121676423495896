import { gql } from 'graphql-request';

import {
  TeamClientStatus,
  TeamID,
  TeamLastPaymentDate,
  TeamNanoID,
  TeamUUID,
  TeamFirstPaymentDate,
  TeamGeneralLedgerID,
  TeamOwnerPayoneerPayoutsBlocked,
  TeamCoefficient
} from '../teamsTypes';

export interface FetchCompanyQueryResponse {
  id: TeamID;
  uuid: TeamUUID;
  nanoId: TeamNanoID;
  bitClientStatus: TeamClientStatus;
  bitFirstPaymentDate: TeamFirstPaymentDate;
  bitLastPaymentDate: TeamLastPaymentDate;
  generalLedgerId: TeamGeneralLedgerID;
  coefficient: TeamCoefficient;
  owner: {
    payoneerPayoutsBlocked: TeamOwnerPayoneerPayoutsBlocked;
  };
}

export const FETCH_COMPANY_QUERY = gql`
  query Company($uuid: ID!) {
    team(uuid: $uuid) {
      bitClientStatus
      bitFirstPaymentDate
      bitLastPaymentDate
      id
      nanoId
      uuid
      generalLedgerId
      coefficient
      owner {
        payoneerPayoutsBlocked
        payoneerPayoutsBlockedById
      }
    }
  }
`;
