import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface AiSearchKeyType {
  aiPoweredCLIPSearch: LocalesKey;
  aiPoweredSearch20: LocalesKey;
  aiPoweredSearch30: LocalesKey;
  aiPoweredSearch: LocalesKey;
  aiPoweredSearchDetect: LocalesKey;
  findAlternatives: LocalesKey;
  imageSearch: LocalesKey;
  objectsFoundInTheSourceImage: LocalesKey;
  pleaseUploadASinglePhoto: LocalesKey;
  search: LocalesKey;
  sourceImage: LocalesKey;
  sourceProduct: LocalesKey;
  uploadAPhotoOfARoom: LocalesKey;
  uploadAPhotoOfYourFurnitureAndWellHelpYouFindMatchingItems: LocalesKey;
}

export const aiSearchKeys = keyPathMirror<AiSearchKeyType, string>(
  {
    aiPoweredCLIPSearch: null,
    aiPoweredSearch20: null,
    aiPoweredSearch30: null,
    aiPoweredSearch: null,
    aiPoweredSearchDetect: null,
    findAlternatives: null,
    imageSearch: null,
    objectsFoundInTheSourceImage: null,
    pleaseUploadASinglePhoto: null,
    search: null,
    sourceImage: null,
    sourceProduct: null,
    uploadAPhotoOfARoom: null,
    uploadAPhotoOfYourFurnitureAndWellHelpYouFindMatchingItems: null
  },
  'models.aiSearch.'
);
