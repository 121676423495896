import {
  BooleanFilter,
  DateFilterType,
  FetchItemCacheKey,
  FetchItemFetched,
  FetchItemGqlQuery,
  FetchItemIsPlaceholderData,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  IdFilterType,
  NanoIDFilter,
  NanoIdFilterType,
  NumberFilterType,
  StringFilter,
  TextFilterType,
  UUIDFilter
} from '../../../types';
import { TagID } from '../../tags/tagsTypes';
import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../../common/hooks/useTableCheckable';
import {
  TaskBurning,
  TaskFavorite,
  TaskMemberIdsFilter,
  TaskPerformerIdsFilter,
  TaskRankedNameFilter,
  TaskStatuses
} from './tasksFields';

import { UpdateIndexQueryItemCacheAction } from '../../common/hooks/base/reactQuery/useIndexQuery';
import { UserNanoID } from '../../users/usersTypes';
import { TeamNanoID } from '../../teams/teamsTypes';

export interface FetchTasksFilters {
  id?: IdFilterType;
  amountCents?: NumberFilterType;
  badDebt?: BooleanFilter;
  burning?: TaskBurning;
  createdAt?: DateFilterType;
  debtUserNanoId?: NanoIDFilter;
  debtUserUuid?: UUIDFilter;
  favorite?: TaskFavorite;
  implementationDate?: DateFilterType;
  lastActivity?: DateFilterType;
  memberFullName?: StringFilter;
  memberIds?: TaskMemberIdsFilter;
  memberNanoIds?: UserNanoID[];
  name?: TextFilterType;
  notes?: TextFilterType;
  performerIds?: TaskPerformerIdsFilter;
  projectNanoId?: NanoIDFilter;
  projectUuid?: UUIDFilter;
  rankedName?: TaskRankedNameFilter;
  resultsUploadedAt?: DateFilterType;
  sourceFilesCount?: NumberFilterType;
  startDate?: DateFilterType;
  status?: TextFilterType;
  statusUpdatedAt?: DateFilterType;
  tagIds?: TagID[];
  teamNanoIds?: TeamNanoID[];
  visibleForClient?: BooleanFilter;
  visibleResultsCount?: NumberFilterType;
  withoutSourceFiles?: BooleanFilter;
}

export interface FetchFinTasksFilters {
  projectNanoId?: NanoIdFilterType;
  status?: TextFilterType;
  visibleResultsCount?: NumberFilterType;
  tagId?: IdFilterType;
}

export type FetchTasksFiltersFunc = (nextFilters: FetchTasksFilters) => void;
export type FetchTasksFilterTasks = FetchItemsFilterItems<FetchTasksFilters>;
export type FetchTasksClearTasksFilters = FetchItemsClearItemsFilters;

export enum FetchTasksSortableFields {
  ID = 'id',
  NAME = 'name',
  IMPLEMENTATION_DATE = 'implementation_date',
  STATUS = 'status',
  STATUS_NUMBER = 'status_number',
  AMOUNT = 'amount_cents',
  PREPAYMENT = 'prepayment_cents',
  RESULTS_UPLOADED_AT = 'results_uploaded_at',
  CREATED_AT = 'createdAt',
  LAST_ACTIVITY = 'last_activity',
  START_DATE = 'start_date',
  STATUS_UPDATED_AT = 'status_updated_at'
}

export enum FetchTasksFilterFields {
  AMOUNT_CENTS = 'amountCents',
  BURNING = 'burning',
  CREATED_AT = 'createdAt',
  START_DATE = 'startDate',
  FAVORITE = 'favorite',
  IMPLEMENTATION_DATE = 'implementationDate',
  MEMBER_IDS = 'memberIds',
  NAME = 'name',
  NOTES = 'notes',
  PERFORMER_IDS = 'performerIds',
  PROJECT_NANO_ID = 'projectNanoId',
  RANKED_NAME = 'rankedName',
  SOURCE_FILES_COUNT = 'sourceFilesCount',
  STATUS = 'status',
  STATUS_IN = 'status.in',
  STATUS_EQ = 'status.eq',
  STATUS_NOT_EQ = 'status.notEq',
  STATUS_UPDATED_AT = 'statusUpdatedAt',
  TAG_IDS = 'tagIds',
  TEAM_NANO_IDS = 'teamNanoIds',
  LAST_ACTIVITY = 'lastActivity',
  VISIBLE_RESULTS_COUNT = 'visibleResultsCount'
}

export enum FetchTasksSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  IMPLEMENTATION_DATE_ASC = 'IMPLEMENTATION_DATE_ASC',
  IMPLEMENTATION_DATE_DESC = 'IMPLEMENTATION_DATE_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  STATUS_NUMBER_ASC = 'STATUS_NUMBER_ASC',
  STATUS_NUMBER_DESC = 'STATUS_NUMBER_DESC',
  STATUS_NUMBER_ASC_ASC = 'STATUS_NUMBER_ASC_ASC',
  STATUS_NUMBER_DESC_DESC = 'STATUS_NUMBER_DESC_DESC',
  START_DATE_ASC = 'START_DATE_ASC',
  START_DATE_DESC = 'START_DATE_DESC',
  AMOUNT_ASC = 'AMOUNT_ASC',
  AMOUNT_DESC = 'AMOUNT_DESC',
  PREPAYMENT_ASC = 'PREPAYMENT_ASC',
  PREPAYMENT_DESC = 'PREPAYMENT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  RESULTS_UPLOADED_AT_ASC = 'RESULTS_UPLOADED_AT_ASC',
  RESULTS_UPLOADED_AT_DESC = 'RESULTS_UPLOADED_AT_DESC',
  LAST_ACTIVITY_ASC = 'LAST_ACTIVITY_ASC',
  LAST_ACTIVITY_DESC = 'LAST_ACTIVITY_DESC'
}

export type TaskStatusFilter = {
  eq?: TaskStatuses;
  in?: TaskStatuses[];
  notEq?: TaskStatuses;
  notIn?: TaskStatuses[];
};

export type FetchTasksGqlQuery = FetchItemsGqlQuery;
export type FetchTaskGqlQuery = FetchItemGqlQuery;
export type FetchTasksCacheKey = FetchItemsCacheKey;
export type FetchTasksCacheKeys = FetchTasksCacheKey[];
export type FetchTaskCacheKey = FetchItemCacheKey;
export type FetchToggleTaskFavoriteCacheKey = FetchItemCacheKey;

export type FetchTasksFetched = FetchItemsFetched;
export type FetchTasksErrorMessage = FetchItemsErrorMessage;
export type FetchTasksIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchTasksPage = FetchItemsPage;
export type FetchTasksLimit = FetchItemsLimit;
export type FetchTasksSort = FetchItemsSort; // FetchTasksSortTypes[];
export type FetchTasksTotalCount = FetchItemsTotalCount;
export type FetchTasksSortTasks = FetchItemsSortItems;
export type FetchTasksPaginateTasks = FetchItemsPaginateItems;
export type FetchTasksPrefetchTasks = FetchItemsPrefetchItems;
export type FetchTasksShowTask = FetchItemsShowItem;
export type FetchTasksEditTask = FetchItemsEditItem;

export type FetchTaskFetched = FetchItemFetched;
export type FetchTaskIsPlaceholderData = FetchItemIsPlaceholderData;

export type FetchTasksChecked = Checked;
export type FetchTasksCheckedHash = CheckedHashItem;
export type FetchTasksOnSetCheckedIds = OnSetCheckedIds;
export type FetchTasksCheckedAll = CheckedAll;
export type FetchTasksOnCheckAll = OnCheckAll;

export type UpdatePaginatedTasksItemCacheAction<ItemType> =
  UpdateIndexQueryItemCacheAction<ItemType>;
