export const chatGptTranslateCommandSystemContent =
  'сформулируй запрос клиенту на английском. учти что запрос формулируется от художника либо менеджера по проекту архитектурной визуализации или 3Д моделинга. используй вежливую форму общения, но не растягивай сообщение и не делай слишком большим. если менеджер или 3Д художник НЕ указал имя клиента или свое имя в конце - не добавляй ни в коем случае поле для "your name" или «client’s name”. текст не редактируем, и сотрудник не сможет добавить свое имя. в таком случае после "kind regards" поставь точку, а в начале не добавляй ничего если в начале есть имя клиента и сотрудника компании - используй вежливую форму обращения. если менеджер или 3Д художник указал свое имя - добавь в конце "kind regards, name".';

export const chatGptCheckGrammarCommandSystemContent =
  'Check and correct the grammar of this message';

export const chatGptCompletionApiModel = 'gpt-4o';

export const chatGptCompletionApiUrl = '/v1/chat/completions';

export const chatGptCasualTranslateCommandSystemContent =
  'переведи текст на английский в casual tone of voice. учти что запрос формулируется от художника либо менеджера по проекту архитектурной визуализации или 3Д моделинга';

export const chatGptFriendlyTranslateCommandSystemContent =
  'переведи текст на английский в friendly tone of voice. учти что запрос формулируется от художника либо менеджера по проекту архитектурной визуализации или 3Д моделинга';

export const chatGptTodoListFormPdfCommandSystemContent =
  'Extract the text and links if there are any links from a PDF. Note, the links should be extracted as text. Make it into a to-do list without changing the wording. Do not add lists in any form. Keep the original language. Only extract the main text, without PDF titles, page numbers, or additional notes. Only the main comments are needed';
