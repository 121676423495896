export default {
  add: 'Add material to library',
  all: 'All materials',
  attachSelected: 'Attach selected materials',
  download: 'Download material',
  deleteMaterial: 'Delete material',
  deleteWarning:
    'Are you sure you want to delete the material? This action cannot be undone.',
  my: 'My Materials',
  plural: 'Materials',
  singular: 'Material',
  addMaterial: 'Add material',
  attach: 'Attach materials',
  attachMaterial: 'Attach material',
  category: 'Material category',
  editMaterial: 'Edit material',
  name: 'Material name',
  search: 'Search materials',
  searchMaterialsByName: 'Search materials by name',
  selected: 'Selected materials',
  viewAll: 'View all materials',
  oneOrMoreSkuWasNotFoundInMaterialLibrary:
    'One or more SKU was not found in material library'
};
