import { request } from 'graphql-request';

import { GRAPHQL_READ_API_URI } from '../../../../../../../config';

import {
  FetchItemsFilters,
  FetchItemsGqlQuery
} from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

interface FetchTotalsProps {
  query: FetchItemsGqlQuery;
  filters: FetchItemsFilters;
}

export function fetchTotals({ query, filters }: FetchTotalsProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole(
    'log',
    'fetchTotals',
    { filters },
    rawName,
    JSON.stringify(filters)
  );

  const requestId = generateUuid();

  return request(
    `${GRAPHQL_READ_API_URI}?queryName=${name}&requestId=${requestId}`,
    query,
    filters,
    { requestId }
  );
}
