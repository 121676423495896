import {
  FetchProductsPathQueryFilters,
  ProductCategoryID,
  ProductClientID,
  ProductNanoID,
  ProductsFiltersNavBasePath
} from './productsTypes';

const basePath = '/products';
const libraryBasePath = '/library/products';

export class ProductPath {
  static index() {
    return basePath;
  }

  static libraryIndex() {
    return libraryBasePath;
  }

  static show(nanoId: ProductNanoID) {
    return `${basePath}/${nanoId}`;
  }

  static edit(nanoId: ProductNanoID) {
    return `${basePath}/${nanoId}/edit`;
  }

  static libraryUser(clientId: ProductClientID) {
    return `${libraryBasePath}/${clientId}`;
  }

  static indexFiltersQuery(filters: FetchProductsPathQueryFilters) {
    return `${basePath}?filters=${JSON.stringify(filters)}`;
  }
  static indexFiltersCustomBaseQuery(
    customBasePath: ProductsFiltersNavBasePath,
    filters: FetchProductsPathQueryFilters
  ) {
    return `${customBasePath}?filters=${JSON.stringify(filters)}`;
  }

  static productsCategory(id: ProductCategoryID) {
    return `${basePath}/categories/${id}`;
  }

  static create() {
    return `${basePath}/create`;
  }
}
