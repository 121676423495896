import { Fragment, memo } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';

import { PrimaryNavTabsEnum } from '../../../hooks/useMenu/types';

import {
  useCompactWorkspaceSecondaryMenu,
  workspaceMenuLinks
} from '../../../../main/common/components/menus/CompactWorkspaceSecondaryMenu/hooks/useCompactWorkspaceSecondaryMenu';
import {
  paymentsMenuLinks,
  useCompactPaymentsSecondaryMenu
} from '../../../../main/common/components/menus/CompactPaymentsSecondaryMenu/hooks/useCompactPaymentsSecondaryMenu';

import { CheckPermissions } from '../../../../helpers/CheckPermissions';
import { PrimaryNavTab } from './helpers/PrimaryNavTab';
import { PrimaryNavTooltipMenuTab } from './helpers/PrimaryNavTooltipMenuTab';
import { TooltipSingletonSourceWrapper } from '../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { InvoicePath } from '../../../../main/invoices/InvoicePath';
import { NotificationPath } from '../../../../main/notifications/NotificationPath';
import { PermissionPath } from '../../../../main/permissions/PermissionPath';
import { ProjectPath } from '../../../../main/projects/ProjectPath';
import { StockPath } from '../../../../main/stock/StockPath';
import { TeamPath } from '../../../../main/teams/TeamPath';
import { TaskPath } from '../../../../main/tasks/TaskPath';
import { SupersetReportPath } from '../../../../main/supersetReports/SupersetReportPath';

import { menuKeys } from '../../../../locales/keys';

import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';

import { AppPermissions } from '../../../appConstants';

function PrimaryNav() {
  const { permissions: workspacePermissions } =
    useCompactWorkspaceSecondaryMenu();
  const { permissions: paymentsPermissions } =
    useCompactPaymentsSecondaryMenu();

  return (
    <Fragment>
      <TooltipSingletonSourceWrapper
        placement={TooltipPlacement.RIGHT}
        withArrow
      >
        <CheckPermissions action={AppPermissions.READ_MENU_NOTIFICATIONS_TAB}>
          <PrimaryNavTab
            href={NotificationPath.index()}
            icon={IconsEnum.BELL}
            tooltipI18nText={menuKeys.tabs.notifications}
            tab={PrimaryNavTabsEnum.NOTIFICATIONS}
          />
        </CheckPermissions>

        <CheckPermissions
          action={AppPermissions.READ_MENU_TASKS_AND_PROJECTS_TAB}
        >
          <PrimaryNavTab
            href={ProjectPath.index()}
            icon={IconsEnum.PROJECTS}
            tooltipI18nText={menuKeys.tabs.workspace}
            tab={PrimaryNavTabsEnum.TASKS_AND_PROJECTS}
          />
        </CheckPermissions>

        <CheckPermissions
          action={AppPermissions.READ_MENU_COMPACT_WORKSPACE_TAB}
        >
          <PrimaryNavTooltipMenuTab
            addClassName="workspace-menu-tour"
            href={TaskPath.compactIndex()}
            tooltipI18nText={menuKeys.tabs.workspace}
            tab={PrimaryNavTabsEnum.COMPACT_WORKSPACE}
            icon={IconsEnum.PROJECTS}
            tooltipLinks={workspaceMenuLinks(workspacePermissions)}
          />
        </CheckPermissions>

        <CheckPermissions action={AppPermissions.READ_MENU_COMPANY_LIBRARY_TAB}>
          <PrimaryNavTab
            href={TeamPath.currentCompanyLibrary()}
            icon={IconsEnum.CUBE_OUTLINE}
            tooltipI18nText={menuKeys.tabs.myLibrary}
            tab={PrimaryNavTabsEnum.COMPANY_LIBRARY}
          />
        </CheckPermissions>

        <CheckPermissions action={AppPermissions.READ_MENU_STOCK_TAB}>
          <PrimaryNavTab
            addClassName="three-d-stock-menu-tour"
            href={StockPath.index()}
            icon={IconsEnum.CUBES_OUTLINE}
            tooltipI18nText={menuKeys.tabs.stock3d}
            tab={PrimaryNavTabsEnum.STOCK}
          />
        </CheckPermissions>

        <CheckPermissions action={AppPermissions.READ_MENU_PEOPLE_TAB}>
          <PrimaryNavTab
            href={TeamPath.companiesMyCompanies()}
            icon={IconsEnum.USER_GROUP}
            tooltipI18nText={menuKeys.tabs.people}
            tab={PrimaryNavTabsEnum.PEOPLE}
          />
        </CheckPermissions>

        <CheckPermissions action={AppPermissions.READ_MENU_PAYMENTS_TAB}>
          <PrimaryNavTooltipMenuTab
            addClassName="payments-menu-tour"
            href={InvoicePath.paymentsInvoices()}
            icon={IconsEnum.CASH_OUTLINE}
            tooltipI18nText={menuKeys.tabs.payments}
            tab={PrimaryNavTabsEnum.PAYMENTS}
            tooltipLinks={paymentsMenuLinks(paymentsPermissions)}
          />
        </CheckPermissions>

        <CheckPermissions action={AppPermissions.READ_MENU_REPORTS_TAB}>
          <PrimaryNavTab
            href={SupersetReportPath.index()}
            icon={IconsEnum.DOCUMENT_REPORT_OUTLINE}
            tooltipI18nText={menuKeys.tabs.reports}
            tab={PrimaryNavTabsEnum.REPORTS}
          />
        </CheckPermissions>

        <CheckPermissions action={AppPermissions.READ_MENU_ADMIN_TAB}>
          <PrimaryNavTab
            href={PermissionPath.index()}
            icon={IconsEnum.SHIELD_EXCLAMATION}
            tooltipI18nText={menuKeys.tabs.admin}
            tab={PrimaryNavTabsEnum.ADMIN}
          />
        </CheckPermissions>
      </TooltipSingletonSourceWrapper>
    </Fragment>
  );
}

export default memo(PrimaryNav);
