import { useCallback, useEffect, useReducer, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ClientError } from 'graphql-request';
import isEqual from 'lodash/isEqual';

import { LocalForage } from '../../../../../../utils/LocalForage';

import {
  UUID,
  FetchItemsGqlQuery,
  FetchItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemCacheKey,
  FetchItemsFilters,
  FetchItemsSort,
  FetchItemsPage,
  FetchItemsLimit
} from '../../../../../../types';

import { useUpdateIndexQueryItemCache } from '../useIndexQuery/hooks/useUpdateIndexQueryItemCache';

import {
  indexRequestReducer,
  IndexRequestReducerType
} from '../useIndexQuery/reducers/indexRequestReducer';

import { fetchReadItems } from '../baseActions/fetchReadItems';
import { fetchReadItem } from '../baseActions/fetchReadItem';

import { changeItemsFiltersAction } from '../useIndexQuery/actions/changeItemsFiltersAction';
import { clearItemsFiltersAction } from '../useIndexQuery/actions/clearItemsFiltersAction';
import { filterItemsAction } from '../useIndexQuery/actions/filterItemsAction';
import { sortItemsAction } from '../useIndexQuery/actions/sortItemsAction';
import { paginateItemsAction } from '../useIndexQuery/actions/paginateItemsAction';
import { limitItemsAction } from '../useIndexQuery/actions/limitItemsAction';

import {
  INITIAL_FILTERS,
  INITIAL_LIMIT,
  INITIAL_PAGE,
  INITIAL_SORT
} from '../useIndexQuery/indexRequestConstants';

import {
  IndexQueryResponse,
  IndexQueryDefaultOptionsOpts
} from '../useIndexQuery/useIndexQuery.types';

import { parseRequestError } from '../../../../../../utils/parseRequestError';

type IndexQueryErrorType = Error | ClientError;

interface IndexQueryDefaultOptions<NodeType, MetaType> {
  cacheKey: FetchItemsCacheKey;
  query: FetchItemsGqlQuery;
  scope: string;
  initialFilters?: FetchItemsFilters;
  initialSort?: FetchItemsSort;
  initialPage?: FetchItemsPage;
  initialLimit?: FetchItemsLimit;
  options?: IndexQueryDefaultOptionsOpts<NodeType, MetaType>;
}

interface IndexQueryWithPrefetchReadItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemGqlQuery;
}

interface IndexQueryWithoutPrefetchReadItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type IndexQueryOptions<NodeType, MetaType> = IndexQueryDefaultOptions<
  NodeType,
  MetaType
> &
  (
    | IndexQueryWithPrefetchReadItemOptions
    | IndexQueryWithoutPrefetchReadItemOptions
  );

function useReadIndexQuery<NodeType, MetaType = undefined>({
  cacheKey,
  scope,
  query,
  initialFilters = INITIAL_FILTERS,
  initialSort = INITIAL_SORT,
  initialPage = INITIAL_PAGE,
  initialLimit = INITIAL_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: IndexQueryOptions<NodeType, MetaType>) {
  const localForageCacheKey = `${cacheKey}-index`;

  const [{ currentFilters, currentLimit, currentPage, currentSort }, dispatch] =
    useReducer<IndexRequestReducerType>(indexRequestReducer, {
      currentPage: initialPage,
      currentLimit: initialLimit,
      currentFilters: initialFilters,
      currentSort: initialSort
    });

  const { data: placeholderData, isFetched: placeholderDataFetched } =
    useQuery<IndexQueryResponse<NodeType, MetaType> | null>(
      `${cacheKey}-placeholder`,
      () =>
        LocalForage.getItem<IndexQueryResponse<NodeType, MetaType>>(
          localForageCacheKey
        ),
      { enabled: options.enabledPlaceholder }
    );

  const currentParams = useMemo(
    () => ({
      filters: currentFilters,
      sort: currentSort,
      page: currentPage,
      limit: currentLimit
    }),
    [currentFilters, currentSort, currentPage, currentLimit]
  );

  const fullCacheKey = useMemo(() => {
    return [cacheKey, currentParams];
  }, [cacheKey, currentParams]);

  const { data, isFetched, isLoading, error, isPlaceholderData } = useQuery<
    IndexQueryResponse<NodeType, MetaType>,
    IndexQueryErrorType
  >(
    fullCacheKey,
    () =>
      fetchReadItems({
        query,
        ...currentParams
      }),
    {
      enabled: options.enabled || placeholderDataFetched,
      cacheTime: options.cacheTime,
      staleTime: options.staleTime,
      refetchInterval: options.refetchInterval,
      keepPreviousData: options.keepPreviousData,
      refetchIntervalInBackground: options.refetchIntervalInBackground,
      onSuccess: (data) => {
        options.onSuccess?.(data);
        if (
          isEqual(currentFilters, initialFilters) &&
          isEqual(currentSort, initialSort) &&
          currentPage === initialPage &&
          currentLimit === initialLimit
        ) {
          return LocalForage.setItem<IndexQueryResponse<NodeType, MetaType>>(
            localForageCacheKey,
            data
          );
        }
      },
      placeholderData: () => {
        if (
          placeholderData &&
          isEqual(currentFilters, initialFilters) &&
          isEqual(currentSort, initialSort) &&
          currentPage === initialPage &&
          currentLimit === initialLimit
        ) {
          return placeholderData;
        }
      }
    }
  );

  const queryClient = useQueryClient();

  const queryResponseValue = data?.[scope];

  useEffect(() => {
    if (options.withoutPrefetch) {
      return;
    }

    const params = {
      filters: currentFilters,
      sort: currentSort,
      page: currentPage + 1,
      limit: currentLimit
    };

    queryClient.prefetchQuery([cacheKey, params], () =>
      fetchReadItems({
        query,
        ...params
      })
    );
  }, [
    cacheKey,
    queryClient,
    query,
    currentFilters,
    currentSort,
    currentPage,
    currentLimit,
    queryResponseValue?.paginationInfo?.nextPage,
    options.withoutPrefetch
  ]);

  const nodesTotalCount = queryResponseValue?.nodes?.length
    ? queryResponseValue?.paginationInfo?.totalCount ||
      placeholderData?.[scope]?.paginationInfo?.totalCount ||
      0
    : null;

  const isLoadingTotalCount = isLoading
    ? placeholderData?.[scope]?.paginationInfo?.totalCount
    : null;

  const defaultTotalCount = 0;

  const itemsTotalCount =
    nodesTotalCount || isLoadingTotalCount || defaultTotalCount;

  const updateItemCache = useUpdateIndexQueryItemCache<NodeType>({
    fullCacheKey,
    scope
  });

  return {
    data,
    items: queryResponseValue?.nodes || <NodeType[]>[],
    itemsError: parseRequestError(error),
    itemsErrorMessage: parseRequestError(error),
    itemsTotalCount,
    meta: queryResponseValue?.meta || <MetaType>{},
    isFetched,
    isLoading,
    isPlaceholderData,
    currentFilters,
    currentSort,
    currentPage,
    currentLimit,
    updateItemCache,
    filterItems: useCallback(
      (nextFilters: FetchItemsFilters) =>
        dispatch(filterItemsAction(nextFilters)),
      [dispatch]
    ),
    changeItemsFilters: useCallback(
      (
        changedFilters: Partial<FetchItemsFilters>,
        removeFilters: string[] = []
      ) => dispatch(changeItemsFiltersAction(changedFilters, removeFilters)),
      [dispatch]
    ),
    clearItemsFiltersPersistInitial: useCallback(
      () => dispatch(filterItemsAction(initialFilters)),
      [dispatch, initialFilters]
    ),
    clearItemsFilters: useCallback(
      () => dispatch(clearItemsFiltersAction()),
      [dispatch]
    ),
    sortItems: useCallback(
      (nextSort: FetchItemsSort) => dispatch(sortItemsAction(nextSort)),
      [dispatch]
    ),
    paginateItems: useCallback(
      (nextPage: FetchItemsPage) => dispatch(paginateItemsAction(nextPage)),
      [dispatch]
    ),
    limitItems: useCallback(
      (nextLimit: FetchItemsLimit) => dispatch(limitItemsAction(nextLimit)),
      [dispatch]
    ),
    prefetchItems: useCallback(
      ({
        nextFilters,
        nextSort,
        nextPage,
        nextLimit
      }: {
        nextFilters?: FetchItemsFilters;
        nextSort?: FetchItemsSort;
        nextPage?: FetchItemsPage;
        nextLimit?: FetchItemsLimit;
      }) => {
        const params = {
          filters: nextFilters || currentFilters,
          sort: nextSort || currentSort,
          page: nextPage || currentPage,
          limit: nextLimit || currentLimit
        };
        return queryClient.prefetchQuery([cacheKey, params], () =>
          fetchReadItems({
            query,
            ...params
          })
        );
      },
      [
        queryClient,
        query,
        cacheKey,
        currentFilters,
        currentSort,
        currentPage,
        currentLimit
      ]
    ),
    prefetchItem: useCallback(
      (itemUuid: UUID) =>
        queryClient.prefetchQuery(
          [fetchItemCacheKey, itemUuid],
          () =>
            fetchReadItem({
              query: fetchItemQuery,
              uuid: itemUuid
            }),
          { staleTime: 5000 }
        ),
      [queryClient, fetchItemCacheKey, fetchItemQuery]
    )
  };
}

export default useReadIndexQuery;
