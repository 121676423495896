import { request } from 'graphql-request';

import { GRAPHQL_READ_FIN_API_URI } from '../../../../../../../config';

import {
  FetchItemGqlQuery,
  ID,
  NanoID,
  UUID
} from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

interface FetchFinItemProps {
  query: FetchItemGqlQuery;
  uuid: UUID | NanoID | ID;
}

export function fetchFinItem({ query, uuid }: FetchFinItemProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole('log', 'fetchFinItem', uuid, rawName);

  const requestId = generateUuid();

  return request(
    `${GRAPHQL_READ_FIN_API_URI}?queryName=${name}&requestId=${requestId}`,
    query,
    { uuid },
    { requestId }
  );
}
