import {
  FetchProjectsFilterFields,
  FetchProjectsSortTypes
} from './projectsTypes';

import { statusesKeys } from '../../locales/keys/models/statuses';

export const INITIAL_PROJECTS_FILTERS = {};
export const INITIAL_PROJECTS_PAGE = 1;
export const INITIAL_PROJECTS_SORT = [FetchProjectsSortTypes.CREATED_AT_DESC];
export const INITIAL_PROJECTS_LIMIT = 50;

export const PROJECT_DEFAULT_IMPLEMENTATION_DATE_TIME = '02:59:00';

export const enum ProjectTabs {
  ACTIVITY = 'activity',
  ATTACHMENTS = 'attachments',
  BATCH_OPERATIONS = 'batch_operations',
  BRIEF = 'brief',
  DEPOSIT_INVOICES = 'deposit-invoices',
  FIN_INVOICES = 'fin-invoices',
  INVOICES = 'invoices',
  JOURNAL_RECORDS = 'journal-records',
  MESSAGES = 'messages',
  PAYMENTS = 'payments',
  PROFORMA = 'proforma',
  RESULTS = 'results',
  TASKS = 'tasks',
  TEAM = 'team',
  TRANSACTIONS = 'transactions',
  WHITEBOARD = 'whiteboard',
  WHITEBOARD_BACKUPS = 'whiteboard-backups',
  FINANCE = 'finance'
}

export const enum ProjectsPermissions {
  READ_PROJECTS_TABLE_DEBT_FIELD = 'read_projects_table_debt_field',
  READ_PROJECTS_COMPACT_RESULTS_PAGE = 'read_projects_compact_results_page',
  READ_PROJECTS_COMPACT_INDEX_PAGE_MENU_COMPANIES_FILTER = 'read_projects_compact_index_page_menu_companies_filter',
  READ_CREATE_PROJECT_IN_TEAM_ITEM_TYPES_HELP_MODAL_BUTTON = 'read_create_project_in_team_item_types_help_modal_button',
  READ_PROJECTS_SETTING_TASK_TAB_BADGE = 'read_projects_setting_task_tab_badge',
  READ_PROJECTS_IN_PROGRESS_TAB_BADGE = 'read_projects_in_progress_tab_badge',
  READ_PROJECTS_DONE_TAB_BADGE = 'read_projects_done_tab_badge',
  READ_PROJECTS_CANCELED_TAB_BADGE = 'read_projects_canceled_tab_badge',
  READ_PROJECTS_QUALITY_CONTROL_TAB_BADGE = 'read_projects_quality_control_tab_badge',
  READ_PROJECTS_READY_FOR_ACCEPTANCE_TAB_BADGE = 'read_projects_ready_for_acceptance_tab_badge',
  READ_PROJECTS_PENDING_MORE_INFO_TAB_BADGE = 'read_projects_pending_more_info_tab_badge',
  READ_PROJECTS_COMPACT_INDEX_PAGE = 'read_projects_compact_index_page',
  READ_PROJECTS_ATTACHMENTS_DELETE_SELECTED_LIFESTYLE_BUTTON = 'read_projects_attachments_delete_selected_lifestyle_button',
  READ_PROJECTS_ATTACHMENTS_DELETE_SELECTED_PRODUCT_BUTTON = 'read_projects_attachments_delete_selected_product_button',
  READ_PROJECTS_ATTACHMENTS_DELETE_SELECTED_MATERIAL_BUTTON = 'read_projects_attachments_delete_selected_material_button',
  READ_PROJECTS_ATTACHMENTS_DELETE_FILE_BUTTON = 'read_projects_attachments_delete_file_button',
  READ_PROJECTS_ATTACHMENTS_DELETE_IMAGE_BUTTON = 'read_projects_attachments_delete_image_button',
  READ_PROJECTS_ATTACHMENTS_REMOVE_FILE_BUTTON = 'read_projects_attachments_remove_file_button',
  READ_PROJECTS_ATTACHMENTS_REMOVE_IMAGE_BUTTON = 'read_projects_attachments_remove_image_button',
  READ_PROJECTS_ATTACHMENTS_VIEW_3D_MODEL_BUTTON = 'read_projects_attachments_view_3d_model_button',
  READ_PROJECTS_ATTACHMENTS_SUBMIT_SELECTED_MATERIALS_MODAL_BUTTON = 'read_projects_attachments_submit_selected_materials_modal_button',
  READ_PROJECTS_RESULTS_VIEW_3D_MODEL_BUTTON = 'read_projects_results_view_3d_model_button',
  READ_PROJECTS_INDEX_PAGE = 'read_projects_index_page',
  READ_PROJECT_BRIEF_PAGE = 'read_project_brief_page',
  READ_PROJECT_TASKS_PAGE = 'read_project_tasks_page',
  READ_PROJECT_TASKS_COMPACT_PAGE = 'read_project_tasks_compact_page',
  READ_PROJECT_IMPORT_TASKS_PAGE = 'read_project_import_tasks_page',
  READ_PROJECT_MESSAGES_PAGE = 'read_project_messages_page',
  READ_PROJECT_ATTACHMENTS_PAGE = 'read_project_attachments_page',
  READ_PROJECT_FILES_PAGE = 'read_project_files_page',
  READ_PROJECT_IMAGES_PAGE = 'read_project_images_page',
  READ_PROJECT_PRODUCTS_PAGE = 'read_project_products_page',
  READ_PROJECT_LIFESTYLES_PAGE = 'read_project_lifestyles_page',
  READ_PROJECT_MATERIALS_PAGE = 'read_project_materials_page',
  READ_PROJECT_RESULTS_PAGE = 'read_project_results_page',
  READ_PROJECT_TEAM_PAGE = 'read_project_team_page',
  READ_PROJECTS_TABLE_COMPANY_AVATAR_FIELD = 'read_projects_table_company_avatar_field',
  READ_PROJECTS_TABLE_USER_FIELD = 'read_projects_table_user_field',
  READ_PROJECTS_TABLE_TEAM_FIELD = 'read_projects_table_team_field',
  READ_PROJECT_NOTIFICATIONS_PAGE = 'read_project_notifications_page',
  READ_PROJECT_TRANSACTIONS_PAGE = 'read_project_transactions_page',
  READ_PROJECT_ACCEPT_PAGE = 'read_project_accept_page',
  READ_PROJECT_SEND_INVOICE_BUTTON = 'read_project_send_invoice_button',
  READ_PROJECT_LEAVE_BUTTON = 'read_project_leave_button',
  READ_PROJECT_INVOICES_PAGE = 'read_project_invoices_page',
  READ_PROJECT_JOURNAL_RECORDS_PAGE = 'read_project_journal_records_page',
  READ_PROJECT_JOURNAL_RECORDS_TABLE = 'read_project_journal_records_table',
  READ_PROJECT_FIN_INVOICES_PAGE = 'read_project_fin_invoices_page',
  READ_PROJECT_ACTIVITY_PAGE = 'read_project_activity_page',
  READ_PROJECT_BATCH_OPERATIONS_PAGE = 'read_project_batch_operations_page',
  READ_PROJECTS_SAVED_FILTERS_MENU = 'read_projects_saved_filters_menu',
  READ_PROJECTS_RANKED_NAME_FILTER = 'read_projects_ranked_name_filter',
  READ_PROJECTS_BURNING_FILTER_MENU = 'read_projects_burning_filter_menu',
  READ_PROJECTS_INVITATIONS_FILTER_MENU = 'read_projects_invitations_filter_menu',
  READ_PROJECTS_FAVORITE_FILTER_MENU = 'read_projects_favorite_filter_menu',
  READ_PROJECT_FILE_ATTACHMENT_ASSIGN_TYPE_BUTTON = 'read_project_file_attachment_assign_type_button',
  READ_PROJECT_FILE_ATTACHMENT_TYPE_BADGE = 'read_project_file_attachment_type_badge',
  READ_PROJECTS_MY_PROJECTS_FILTER_MENU = 'read_projects_my_projects_filter_menu',
  READ_NEW_PROJECT_BUTTON = 'read_new_project_button',
  READ_PROJECTS_HEADER_CREATE_PROJECT_IN_TEAM_LINK = 'read_projects_header_create_project_in_team_link',
  READ_PROJECTS_COMPACT_HEADER_CREATE_PROJECT_IN_TEAM_LINK = 'read_projects_compact_header_create_project_in_team_link',
  READ_PROJECTS_CREATE_PROJECT_IN_TEAM_LINK = 'read_projects_create_project_in_team_link',
  READ_PROJECT_PAY_BILLS_BUTTON = 'read_project_pay_bills_button',
  CHANGE_PROJECT_DESCRIPTION = 'change_project_description',
  CHANGE_PROJECT_ESTIMATE_COST = 'change_project_estimate_cost',
  CHANGE_PROJECT_UNDEFINED_IMPLEMENTATION_DATE = 'change_project_undefined_implementation_date',
  CHANGE_PROJECT_IMPLEMENTATION_DATE = 'change_project_implementation_date',
  CHANGE_PROJECT_IMPLEMENTATION_TIME = 'change_project_implementation_time',
  CHANGE_PROJECT_TASK_NAMES = 'change_project_task_names',
  CHANGE_PROJECT_FILE_ATTACHMENT_IDS = 'change_project_file_attachment_ids',
  READ_PROJECT_ESTIMATE_COST = 'read_project_estimate_cost',
  READ_PROJECT_TASKS_AMOUNTS_SUM = 'read_project_tasks_amounts_sum',
  READ_PROJECT_TASKS_CLIENT_PREPAYMENTS_SUM = 'read_project_tasks_client_prepayments_sum',
  READ_PROJECT_TAGS = 'read_project_tags',
  READ_PROJECT_DEBT = 'read_project_debt',
  READ_PROJECT_CLIENT_DEBT = 'read_project_client_debt',
  READ_PROJECT_COMMISSION = 'read_project_commission',
  READ_PROJECT_CREDIT_COMMISSION = 'read_project_credit_commission',
  READ_PROJECT_HOSTING_COMMISSION = 'read_project_hosting_commission',
  CHANGE_PROJECT_COMMISSION = 'change_project_commission',
  CHANGE_PROJECT_CREDIT_COMMISSION = 'change_project_credit_commission',
  CHANGE_PROJECT_HOSTING_COMMISSION = 'change_project_hosting_commission',
  READ_PROJECT_PROJECT_ACCOUNT = 'read_project_project_account',
  READ_PROJECT_TAG_IDS = 'read_project_tag_ids',
  CHANGE_PROJECT_TAG_IDS = 'change_project_tag_ids',
  READ_PROJECT_STATUS = 'read_project_status',
  READ_PROJECT_CLIENT_NEW_LABEL = 'read_project_client_new_label',
  READ_PROJECT_TASKS_CLIENT_STATUS = 'read_project_tasks_client_status',
  READ_PROJECTS_TABLE_CHECKBOXES = 'read_projects_table_checkboxes',
  READ_PROJECTS_TABLE_STATUS_FIELD = 'read_projects_table_status_field',
  READ_PROJECTS_TABLE_TASKS_CLIENT_STATUS_FIELD = 'read_projects_table_tasks_client_status_field',
  READ_PROJECTS_TABLE_PROJECT_ACCOUNT = 'read_projects_table_project_account',
  READ_PROJECTS_TABLE_PROJECT_EXPENSES = 'read_projects_table_project_expenses',
  READ_PROJECT_TRACK_TIME_BUTTON = 'read_project_track_time_button',
  READ_PROJECT_INVITE_USER_BUTTON = 'read_project_invite_user_button',
  READ_PROJECT_FAVORITE_BUTTON = 'read_project_favorite_button',
  READ_PROJECT_TOGGLE_NOTIFICATIONS_BUTTON = 'read_project_toggle_notifications_button',
  READ_PROJECT_UPDATE_NAME_BUTTON = 'read_project_update_name_button',
  READ_PROJECT_UPDATE_DESCRIPTION_BUTTON = 'read_project_update_description_button',
  READ_PROJECT_TEAM_MEMBER_MENU_DROPDOWN = 'read_project_team_member_menu_dropdown',
  READ_PROJECT_TEAM_MEMBER_ASSIGN_AS_CREATOR_BUTTON = 'read_project_team_member_assign_as_creator_button',
  READ_PROJECT_TEAM_MEMBER_ASSIGN_AS_OWNER_BUTTON = 'read_project_team_member_assign_as_owner_button',
  READ_PROJECT_CHANGE_OWNER_BUTTON = 'read_project_change_owner_button',
  READ_PROJECT_MEMBER_TRACKED_TIME = 'read_project_member_tracked_time',
  CREATE_PROJECT_BILL = 'create_project_bill',
  READ_PROJECT_ATTACHMENTS_MATERIALS_FOLDER = 'read_project_attachments_materials_folder',
  READ_PROJECT_CLIENT_MENU_DROPDOWN = 'read_project_client_menu_dropdown',
  READ_PROJECT_ID = 'read_project_id',
  READ_PROJECT_UUID = 'read_project_uuid',
  READ_PROJECT_NANO_ID = 'read_project_nano_id',
  READ_PROJECT_IMPLEMENTATION_DATE = 'read_project_implementation_date',
  READ_PROJECT_CLIENT_LOCAL_TIME = 'read_project_client_local_time',
  READ_PROJECT_ARCHIVIZER_TEAM_LOCAL_TIME = 'read_project_archivizer_team_local_time',
  CREATE_PROJECT_DESCRIPTION = 'create_project_description',
  CREATE_PROJECT_ESTIMATE_COST = 'create_project_estimate_cost',
  CREATE_PROJECT_IMPLEMENTATION_DATE = 'create_project_implementation_date',
  CREATE_PROJECT_IMPLEMENTATION_TIME = 'create_project_implementation_time',
  CREATE_PROJECT_TASK_NAMES = 'create_project_task_names',
  CREATE_PROJECT_FILE_ATTACHMENT_IDS = 'create_project_file_attachment_ids',
  READ_PROJECT_BILLS_BLOCK = 'read_project_bills_block',
  READ_PROJECT_TRANSACTIONS_ID = 'read_project_transactions_id',
  READ_PROJECT_TRANSACTIONS_CREATED_AT = 'read_project_transactions_created_at',
  READ_PROJECT_TRANSACTIONS_AMOUNT = 'read_project_transactions_amount',
  READ_PROJECT_TRANSACTIONS_TOTAL_BALANCE = 'read_project_transactions_total_balance',
  READ_PROJECT_TRANSACTIONS_AUTHOR = 'read_project_transactions_author',
  READ_PROJECT_TRANSACTIONS_KIND = 'read_project_transactions_kind',
  READ_PROJECT_TRANSACTIONS_BALANCE_TYPE = 'read_project_transactions_balance_type',
  READ_PROJECT_TRANSACTIONS_BALANCE = 'read_project_transactions_balance',
  READ_PROJECT_TRANSACTIONS_PAYMENT_TYPE = 'read_project_transactions_payment_type',
  READ_PROJECT_TRANSACTIONS_TRANSACTION_TAGS = 'read_project_transactions_transaction_tags',
  READ_PROJECT_TRANSACTIONS_DESCRIPTION = 'read_project_transactions_description',
  READ_PROJECT_DEPOSIT_INVOICES_TAB = 'read_project_deposit_invoices_tab',
  READ_PROJECT_FINANCE_TAB = 'read_project_finance_tab',
  READ_PROJECT_TASKS_TAB = 'read_project_tasks_tab',
  READ_PROJECT_MESSAGES_TAB = 'read_project_messages_tab',
  READ_PROJECT_BRIEF_TAB = 'read_project_brief_tab',
  READ_PROJECT_ATTACHMENTS_TAB = 'read_project_attachments_tab',
  READ_PROJECT_RESULTS_TAB = 'read_project_results_tab',
  READ_PROJECT_TEAM_TAB = 'read_project_team_tab',
  READ_PROJECT_NOTIFICATIONS_TAB = 'read_project_notifications_tab',
  READ_PROJECT_TRANSACTIONS_TAB = 'read_project_transactions_tab',
  READ_PROJECT_INVOICES_TAB = 'read_project_invoices_tab',
  READ_PROJECT_JOURNAL_RECORDS_TAB = 'read_project_journal_records_tab',
  READ_PROJECT_WHITEBOARD_TAB = 'read_project_whiteboard_tab',
  READ_PROJECT_WHITEBOARD_BACKUPS_TAB = 'read_project_whiteboard_backups_tab',
  READ_PROJECT_ACTIVITY_TAB = 'read_project_activity_tab',
  READ_PROJECTS_TAG_IDS_FILTER = 'read_projects_tag_ids_filter',
  READ_PROJECT_CREATE_BILL_BUTTON = 'read_project_create_bill_button',
  CHANGE_PROJECT_ATTACHMENTS_CHECKED = 'change_project_attachments_checked',
  CHANGE_PROJECT_TASK_ATTACHMENTS_CHECKED = 'change_project_task_attachments_checked',
  READ_PROJECT_TRANSACTIONS_DIRECTION = 'read_project_transactions_direction',
  READ_PROJECTS_BURNING_FILTER = 'read_projects_burning_filter',
  CHANGE_PROJECT_FILES_CHECKED = 'change_project_files_checked',
  CHANGE_PROJECT_IMAGES_CHECKED = 'change_project_images_checked',
  CHANGE_PROJECT_TASK_IMAGES_CHECKED = 'change_project_task_images_checked',
  CHANGE_PROJECT_TASK_FILES_CHECKED = 'change_project_task_files_checked',
  CHANGE_PROJECT_TASK_SOURCE_FILES_CHECKED = 'change_project_task_source_files_checked',
  CHANGE_PROJECT_LIFESTYLES_CHECKED = 'change_project_lifestyles_checked',
  CHANGE_PROJECT_MATERIALS_CHECKED = 'change_project_materials_checked',
  CHANGE_PROJECT_PRODUCTS_CHECKED = 'change_project_products_checked',
  CHANGE_PROJECT_TASK_MATERIALS_CHECKED = 'change_project_task_materials_checked',
  CHANGE_PROJECT_TASK_PRODUCTS_CHECKED = 'change_project_task_products_checked',
  CHANGE_PROJECT_TASK_LIFESTYLES_CHECKED = 'change_project_task_lifestyles_checked',
  READ_DEFAULT_PROJECT_PRICE = 'read_default_project_price',
  READ_PROJECTS_SEARCH_FILTERS_POPOVER = 'read_projects_search_filters_popover',
  READ_PROJECTS_EVALUATE_PROJECTS_BLOCK = 'read_projects_evaluate_projects_block',
  READ_PROJECT_BRIEF_REMOVE_SCOPE_OF_WORK_MESSAGE_BUTTON = 'read_project_brief_remove_scope_of_work_message_button',
  READ_PROJECT_BRIEF_REMOVE_STARRED_MESSAGE_BUTTON = 'read_project_brief_remove_starred_message_button',
  READ_PROJECT_BRIEF_ATTACHMENTS_BLOCK = 'read_project_brief_attachments_block',
  READ_PROJECT_BRIEF_PINNED_MESSAGES_BLOCK = 'read_project_brief_pinned_messages_block',
  READ_PROJECT_BRIEF_FLAGGED_MESSAGES_BLOCK = 'read_project_brief_flagged_messages_block',
  READ_PROJECT_BRIEF_STARRED_MESSAGES_BLOCK = 'read_project_brief_starred_messages_block',
  READ_PROJECTS_EVALUATIONS_FILTER_MENU = 'read_projects_evaluations_filter_menu',
  READ_PROJECT_SIDEBAR_FILE_ATTACHMENT_EDIT_BUTTON = 'read_project_sidebar_file_attachment_edit_button',
  READ_PROJECT_ATTACHMENTS_FILE_ATTACHMENT_EDIT_BUTTON = 'read_project_attachments_file_attachment_edit_button',
  READ_PROJECTS_INVOICES_FILTER_MENU = 'read_projects_invoices_filter_menu',
  READ_PROJECTS_BATCH_CREATE_PROJECTS_BILLS_BUTTON = 'read_projects_batch_create_projects_bills_button',
  READ_PROJECTS_BATCH_INVITE_USERS_TO_PROJECTS_BUTTON = 'read_projects_batch_invite_users_to_projects_button',
  READ_PROJECTS_BATCH_UPDATE_AMOUNTS_BUTTON = 'read_projects_batch_update_amounts_button',
  READ_PROJECT_TEAM_MEMBERS_SELF_USERS = 'read_project_team_members_self_users',
  READ_PROJECT_INVITED_MEMBERS_TABLE = 'read_project_invited_members_table',
  READ_PROJECT_NOTIFICATION_TABLE = 'read_project_notification_table',
  READ_PROJECT_INVITED_MEMBERS_TABLE_DELETE_BUTTON = 'read_project_invited_members_table_delete_button',
  READ_PROJECT_CREATE_MESSAGE_FORM_FULLSCREEN_DROPZONE = 'read_project_create_message_form_fullscreen_dropzone',
  READ_PROJECT_CREATE_MESSAGE_FORM_PASTE_FILES = 'read_project_create_message_form_paste_files',
  READ_PROJECT_TASKS_TABLE_SETTINGS_BUTTON = 'read_project_tasks_table_settings_button',
  READ_PROJECT_TASKS_HIDDEN_FILTER = 'read_project_tasks_hidden_filter',
  READ_PROJECT_TASKS_ONLY_HIDDEN_FILTER = 'read_project_tasks_only_hidden_filter',
  READ_PROJECT_TASKS_CANCELED_FILTER = 'read_project_tasks_canceled_filter',
  READ_PROJECT_SUBMIT_FILE_ATTACHMENTS_MODAL_BUTTON_FULLSCREEN_DROPZONE = 'read_project_submit_file_attachments_modal_button_fullscreen_dropzone',
  READ_PROJECTS_BURNING_FILTER_MENU_COUNT = 'read_projects_burning_filter_menu_count',
  READ_PROJECTS_MY_PROJECTS_FILTER_MENU_COUNT = 'read_projects_my_projects_filter_menu_count',
  READ_PROJECTS_INVITATIONS_FILTER_MENU_COUNT = 'read_projects_invitations_filter_menu_count',
  READ_PROJECTS_EVALUATIONS_FILTER_MENU_COUNT = 'read_projects_evaluations_filter_menu_count',
  READ_PROJECT_TASK_RESULTS_TAB_COUNT = 'read_project_task_results_tab_count',
  READ_PROJECT_BATCH_OPERATIONS_TAB_COUNT = 'read_project_batch_operations_tab_count',
  READ_PROJECT_TASKS_FILTERS = 'read_project_tasks_filters',
  READ_PROJECT_MY_TASKS_FILTERS = 'read_project_my_tasks_filters',
  READ_CREATE_PROJECT_IN_TEAM_PAGE = 'read_create_project_in_team_page',
  READ_CREATE_PROJECT_IN_TEAM_IMPLEMENTATION_DATE = 'read_create_project_in_team_implementation_date',
  READ_CREATE_PROJECT_IN_TEAM_IMPLEMENTATION_TIME = 'read_create_project_in_team_implementation_time',
  READ_CREATE_PROJECT_IN_TEAM_DESCRIPTION = 'read_create_project_in_team_description',
  READ_CREATE_PROJECT_IN_TEAM_CURRENCY = 'read_create_project_in_team_currency',
  READ_CREATE_PROJECT_IN_TEAM_CLEAR_MODAL_BUTTON = 'read_create_project_in_team_clear_modal_button',
  READ_CREATE_PROJECT_IN_TEAM_CREATE_PROJECT_BUTTON = 'read_create_project_in_team_create_project_button',
  READ_CREATE_PROJECT_IN_TEAM_CREATE_PROJECT_AND_INVOICE_MODAL_BUTTON = 'read_create_project_in_team_create_project_and_invoice_modal_button',
  READ_CREATE_PROJECT_IN_TEAM_TEAM_ID = 'read_create_project_in_team_team_id',
  READ_CREATE_PROJECT_AND_INVOICE_MODAL_AV_BILLING_INFO_ID = 'read_create_project_and_invoice_modal_av_billing_info_id',
  CHANGE_CREATE_PROJECT_IN_TEAM_TEAM_ID = 'change_create_project_in_team_team_id',
  READ_CREATE_PROJECT_IN_TEAM_TASKS = 'read_create_project_in_team_tasks',
  READ_CREATE_PROJECT_IN_TEAM_ADD_TASK_BUTTON = 'read_create_project_in_team_add_task_button',
  READ_CREATE_PROJECT_IN_TEAM_REMOVE_TASK_BUTTON = 'read_create_project_in_team_remove_task_button',
  READ_CREATE_PROJECT_IN_TEAM_ITEMS = 'read_create_project_in_team_items',
  READ_CREATE_PROJECT_IN_TEAM_ADD_ITEM_BUTTON = 'read_create_project_in_team_add_item_button',
  READ_CREATE_PROJECT_IN_TEAM_REMOVE_ITEM_BUTTON = 'read_create_project_in_team_remove_item_button',
  CHANGE_CREATE_PROJECT_IN_TEAM_ITEM_QUANTITY = 'change_create_project_in_team_item_quantity',
  CHANGE_CREATE_PROJECT_IN_TEAM_ITEM_PRICE = 'change_create_project_in_team_item_price',
  CHANGE_CREATE_PROJECT_IN_TEAM_ITEM_TYPE_ID = 'change_create_project_in_team_item_type_id',
  READ_CREATE_PROJECT_IN_TEAM_NEW_ITEM_TYPE_BUTTON = 'read_create_project_in_team_new_item_type_button',
  READ_CREATE_PROJECT_IN_TEAM_FILE_ATTACHMENT_IDS = 'read_create_project_in_team_file_attachment_ids',
  READ_CREATE_PROJECT_IN_TEAM_INVOICE_DETAILS = 'read_create_project_in_team_invoice_details',
  READ_CREATE_PROJECT_IN_TEAM_TERMS = 'read_create_project_in_team_terms',
  CHANGE_CREATE_PROJECT_IN_TEAM_TERMS = 'change_create_project_in_team_terms',
  READ_CREATE_PROJECT_IN_TEAM_PAYMENT_METHOD = 'read_create_project_in_team_payment_method',
  CHANGE_CREATE_PROJECT_IN_TEAM_PAYMENT_METHOD = 'change_create_project_in_team_payment_method',
  READ_CREATE_PROJECT_IN_TEAM_BILLING_INFO = 'read_create_project_in_team_billing_info',
  CHANGE_CREATE_PROJECT_IN_TEAM_BILLING_INFO = 'change_create_project_in_team_billing_info',
  READ_CREATE_PROJECT_IN_TEAM_NOTES = 'read_create_project_in_team_notes',
  CHANGE_CREATE_PROJECT_IN_TEAM_NOTES = 'change_create_project_in_team_notes',
  READ_CREATE_PROJECT_IN_TEAM_BILL_FROM = 'read_create_project_in_team_bill_from',
  READ_CREATE_PROJECT_IN_TEAM_BILL_TO = 'read_create_project_in_team_bill_to',
  READ_CREATE_PROJECT_IN_TEAM_TASK_NAME = 'read_create_project_in_team_task_name',
  READ_CREATE_PROJECT_IN_TEAM_TOGGLE_ITEMS_BUTTON = 'read_create_project_in_team_toggle_items_button',
  READ_CREATE_PROJECT_IN_TEAM_TASK_TOTAL = 'read_create_project_in_team_task_total',
  READ_CREATE_PROJECTS_IN_TEAM_PAGE = 'read_create_projects_in_team_page',
  READ_CREATE_PROJECTS_IN_TEAM_TEAM_ID = 'read_create_projects_in_team_team_id',
  READ_CREATE_PROJECTS_IN_TEAM_TASK_TOTAL = 'read_create_projects_in_team_task_total',
  CHANGE_CREATE_PROJECTS_IN_TEAM_TEAM_ID = 'change_create_projects_in_team_team_id',
  READ_CREATE_PROJECTS_IN_TEAM_TASKS = 'read_create_projects_in_team_tasks',
  READ_CREATE_PROJECTS_IN_TEAM_ADD_TASK_BUTTON = 'read_create_projects_in_team_add_task_button',
  READ_CREATE_PROJECTS_IN_TEAM_INVOICE_DETAILS = 'read_create_projects_in_team_invoice_details',
  READ_CREATE_PROJECTS_IN_TEAM_PAYMENT_METHOD = 'read_create_projects_in_team_payment_method',
  CHANGE_CREATE_PROJECTS_IN_TEAM_PAYMENT_METHOD = 'change_create_projects_in_team_payment_method',
  READ_CREATE_PROJECTS_IN_TEAM_BILL_FROM = 'read_create_projects_in_team_bill_from',
  READ_CREATE_PROJECTS_IN_TEAM_BILL_TO = 'read_create_projects_in_team_bill_to',
  CHANGE_CREATE_PROJECTS_IN_TEAM_BILLING_INFO = 'change_create_projects_in_team_billing_info',
  READ_PROJECT_TRANSACTIONS_TABLE_ACCOUNT_BALANCE_FIELD = 'read_project_transactions_table_account_balance_field',
  READ_CREATE_PROJECTS_IN_TEAM_NOTES = 'read_create_projects_in_team_notes',
  CHANGE_CREATE_PROJECTS_IN_TEAM_NOTES = 'change_create_projects_in_team_notes',
  READ_CREATE_PROJECTS_IN_TEAM_TERMS = 'read_create_projects_in_team_terms',
  CHANGE_CREATE_PROJECTS_IN_TEAM_TERMS = 'change_create_projects_in_team_terms',
  READ_CREATE_PROJECTS_IN_TEAM_TASK_NAME = 'read_create_projects_in_team_task_name',
  READ_CREATE_PROJECTS_IN_TEAM_REMOVE_TASK_BUTTON = 'read_create_projects_in_team_remove_task_button',
  READ_CREATE_PROJECTS_IN_TEAM_ITEMS = 'read_create_projects_in_team_items',
  READ_CREATE_PROJECTS_IN_TEAM_REMOVE_PROJECT_BUTTON = 'read_create_projects_in_team_remove_project_button',
  READ_PROJECT_ACTIVE_TASK_FIN_PAYMENTS_BLOCK = 'read_project_active_task_fin_payments_block',
  READ_PROJECT_SIDEBAR_PAYMENTS_BLOCK = 'read_project_sidebar_payments_block',
  READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK = 'read_project_sidebar_fin_payments_block',
  READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_WITH_REGULAR_NET_INVOICES = 'read_project_sidebar_fin_payments_block_with_regular_net_invoices',
  READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_SEND_INVOICES = 'read_project_sidebar_fin_payments_block_send_invoices',
  READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_PAID_INVOICES = 'read_project_sidebar_fin_payments_block_paid_invoices',
  READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_OPEN_INVOICES = 'read_project_sidebar_fin_payments_block_open_invoices',
  READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_CANCELED_INVOICES = 'read_project_sidebar_fin_payments_block_canceled_invoices',
  READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_UNCOLLECTIBLE_INVOICES = 'read_project_sidebar_fin_payments_block_uncollectible_invoices',
  READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_VOID_INVOICES = 'read_project_sidebar_fin_payments_block_void_invoices',
  READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_DRAFT_INVOICES = 'read_project_sidebar_fin_payments_block_draft_invoices',
  READ_PROJECT_SIDEBAR_FIN_DEPOSIT_PAYMENTS_BLOCK = 'read_project_sidebar_fin_deposit_payments_block',
  READ_PROJECT_SIDEBAR_FIN_DEPOSIT_PAYMENTS_BLOCK_SEND_INVOICES = 'read_project_sidebar_fin_deposit_payments_block_send_invoices',
  READ_PROJECT_SIDEBAR_FIN_DEPOSIT_PAYMENTS_BLOCK_PAID_INVOICES = 'read_project_sidebar_fin_deposit_payments_block_paid_invoices',
  READ_PROJECT_SIDEBAR_FIN_DEPOSIT_PAYMENTS_BLOCK_OPEN_INVOICES = 'read_project_sidebar_fin_deposit_payments_block_open_invoices',
  READ_PROJECT_SIDEBAR_FIN_DEPOSIT_PAYMENTS_BLOCK_CANCELED_INVOICES = 'read_project_sidebar_fin_deposit_payments_block_canceled_invoices',
  READ_PROJECT_SIDEBAR_FIN_DEPOSIT_PAYMENTS_BLOCK_UNCOLLECTIBLE_INVOICES = 'read_project_sidebar_fin_deposit_payments_block_uncollectible_invoices',
  READ_PROJECT_SIDEBAR_FIN_DEPOSIT_PAYMENTS_BLOCK_VOID_INVOICES = 'read_project_sidebar_fin_deposit_payments_block_void_invoices',
  READ_PROJECT_SIDEBAR_FIN_DEPOSIT_PAYMENTS_BLOCK_DRAFT_INVOICES = 'read_project_sidebar_fin_deposit_payments_block_draft_invoices',
  READ_PROJECT_SIDEBAR_TEAM_BLOCK = 'read_project_sidebar_team_block',
  READ_PROJECT_SIDEBAR_FILES_BLOCK = 'read_project_sidebar_files_block',
  READ_PROJECT_SIDEBAR_COEFFICIENT_BLOCK = 'read_project_sidebar_coefficient_block',
  READ_PROJECT_SIDEBAR_MEDIA_BLOCK = 'read_project_sidebar_media_block',
  READ_PROJECT_SIDEBAR_FIN_TEAM_BLOCK = 'read_project_sidebar_fin_team_block',
  READ_PROJECT_PAYMENTS_PAGE = 'read_project_payments_page',
  READ_PROJECT_PAYMENTS_TAB = 'read_project_payments_tab',
  READ_PROJECT_PROFORMA_TAB = 'read_project_proforma_tab',
  READ_PROJECT_PAYMENTS_ADD_ITEM_BUTTON = 'read_project_payments_add_item_button',
  READ_PROJECT_PAYMENTS_DRAG_ITEM_BUTTON = 'read_project_payments_drag_item_button',
  READ_CREATE_PROJECTS_IN_TEAM_ADD_ITEM_BUTTON = 'read_create_projects_in_team_add_item_button',
  READ_CREATE_PROJECTS_IN_TEAM_NEW_ITEM_TYPE_BUTTON = 'read_create_projects_in_team_new_item_type_button',
  CHANGE_PROJECT_PAYMENTS_ITEM_PRICE = 'change_project_payments_item_price',
  READ_PROJECT_PAYMENTS_MOVE_TASK_ITEMS_MODAL_BUTTON = 'read_project_payments_move_task_items_modal_button',
  READ_PROJECT_PAYMENTS_CANCEL_MODAL_BUTTON = 'read_project_payments_cancel_modal_button',
  READ_PROJECT_PAYMENTS_PAY_FROM_DEPOSIT_BUTTON = 'read_project_payments_pay_from_deposit_button',
  READ_PROJECT_PAYMENTS_PAY_INTERNAL_BUTTON = 'read_project_payments_pay_internal_button',
  READ_PROJECT_PAYMENTS_TASKS_CHECKBOXES = 'read_project_payments_tasks_checkboxes',
  READ_PROJECT_PAYMENTS_ITEMS_CHECKBOXES = 'read_project_payments_items_checkboxes',
  READ_PROJECT_PAYMENTS_WITHOUT_DONE_FILTER = 'read_project_payments_without_done_filter',
  READ_PROJECT_PAYMENTS_STATUS_FILTER = 'read_project_payments_status_filter',
  READ_PROJECT_PAYMENTS_DOWNLOAD_REPORT_BUTTON = 'read_project_payments_download_report_button',
  READ_PROJECT_TASKS_DOWNLOAD_REPORT_BUTTON = 'read_project_tasks_download_report_button',
  READ_PROJECT_PAYMENTS_SELECTED_ITEMS_TOTAL = 'read_project_payments_selected_items_total',
  READ_PROJECT_PAYMENTS_PARENT_INVOICE_ID_FILTER = 'read_project_payments_parent_invoice_id_filter',
  READ_PROJECTS_NO_PROJECTS_ICON = 'read_projects_no_projects_icon',
  READ_PROJECT_PAYMENTS_VOID_ITEM_BUTTON = 'read_project_payments_void_item_button',
  READ_CREATE_PROJECT_IN_TEAM_CHANGE_BILLING_INFO_MODAL_AV_BILLING_INFO_ID = 'read_create_project_in_team_change_billing_info_modal_av_billing_info_id',
  READ_CREATE_PROJECT_IN_TEAM_OWNER_ID = 'read_create_project_in_team_owner_id',
  READ_CREATE_PROJECT_IN_TEAM_GENERAL_LEDGER_ID = 'read_create_project_in_team_general_ledger_id',
  CHANGE_CREATE_PROJECT_IN_TEAM_GENERAL_LEDGER_ID = 'change_create_project_in_team_general_ledger_id',
  READ_CREATE_PROJECT_IN_TEAM_TASK_USER_IDS = 'read_create_project_in_team_task_user_ids',
  READ_PROJECT_PROFORMA_PAGE = 'read_project_proforma_page',
  READ_PROJECT_PAYMENTS_BIND_ITEM_BUTTON = 'read_project_payments_bind_item_button',
  READ_PROJECT_PAYMENTS_UNBIND_ITEM_BUTTON = 'read_project_payments_unbind_item_button',
  READ_PROJECT_PAYMENTS_ITEMS_BIND_STATUS = 'read_project_payments_items_bind_status',
  READ_PROJECT_DEPOSIT_INVOICES_PAGE = 'read_project_deposit_invoices_page',
  READ_PROJECT_DEPOSIT_INVOICES_PAY_CARD_BUTTON = 'read_project_deposit_invoices_pay_card_button',
  READ_PROJECT_DEPOSIT_INVOICES_CONFIRM_PAYMENT_BUTTON = 'read_project_deposit_invoices_confirm_payment_button',
  READ_PROJECT_WHITEBOARD_PAGE = 'read_project_whiteboard_page',
  READ_PROJECT_WHITEBOARD_BACKUPS_PAGE = 'read_project_whiteboard_backups_page',
  READ_PROJECT_WHITEBOARD_BACKUP_PAGE = 'read_project_whiteboard_backup_page',
  READ_PROJECTS_CREATE_SEARCH_FILTER_MODAL_BUTTON = 'read_projects_create_search_filter_modal_button',
  READ_PROJECT_MESSAGES_CHANNELS_LIST = 'read_project_messages_channels_list',
  READ_PROJECT_ONBOARDING_TOUR = 'read_project_onboarding_tour'
}

export const projectsStatuses = [
  { value: 'definition', i18nLabel: statusesKeys.types.definition },
  {
    value: 'implementation',
    i18nLabel: statusesKeys.types.implementation
  },
  { value: 'acceptance', i18nLabel: statusesKeys.types.acceptance },
  {
    value: 'incomplete_specification',
    i18nLabel: statusesKeys.types.incompleteSpecification
  },
  { value: 'performed', i18nLabel: statusesKeys.types.performed },
  { value: 'canceled', i18nLabel: statusesKeys.types.canceled }
  // { value: 'paused', i18nLabel: statusesKeys.types.paused },
  // { value: 'qa', i18nLabel: statusesKeys.types.qa }
];

export const ignoredCompactProjectsFilters = [FetchProjectsFilterFields.STATUS];

export enum CompactProjectsViews {
  INDEX = 'index',
  RESULTS = 'results'
}

export type CompactProjectsViewsVisibleFieldsByView = {
  [key in CompactProjectsViews]: string[];
};

export const projectSidebarStateCacheKey = 'project-show-sidebar-state';

export const clientProjectSidebarOpen = false;
export const workerProjectSidebarOpen = true;
