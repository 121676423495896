import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import { ErrorProps } from 'next/error';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastProvider } from 'react-toast-notifications';
import { GoogleTagManager } from '@next/third-parties/google';
import { hotjar } from 'react-hotjar';
import { QueryClient } from 'react-query';
import { QueryClientProvider } from '../adapters/libs/react-query';

import {
  GTM_DATA_LAYER_NAME,
  GTM_TRACKING_ID,
  HOTJAR_ID,
  HOTJAR_SV,
  IS_PRODUCTION,
  VERSION
} from '../config';

import { AuthProvider } from '../auth/hooks/useAuth';

import { ThemeProvider } from '../app/hooks/useTheme';

import { RouterHistoryProvider } from '../app/hooks/useRouterHistory';

import { ExtendedFiltersProvider } from '../app/hooks/useExtendedFilters';

import { AppLayout } from '../app/AppLayouts/AppLayout';

import { ToastContainer } from '../helpers/ToastContainer';

import { LocalForage } from '../utils/LocalForage';
import '../utils/i18n';
import { setQueryLogger } from '../utils/setQueryLogger';

import '../styles/globals.scss';

LocalForage.initDriver();

const queryClient = new QueryClient();
setQueryLogger();

const hotJarId = parseInt(HOTJAR_ID, 10);
const hotJarSv = parseInt(HOTJAR_SV, 10);

function MyApp({ Component, pageProps, err }: AppProps & { err: ErrorProps }) {
  console.log(`Version: ${VERSION}`);

  useEffect(() => {
    hotjar.initialize(hotJarId, hotJarSv);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider>
          <ToastProvider placement="top-right" components={{ ToastContainer }}>
            <RouterHistoryProvider>
              <ExtendedFiltersProvider>
                <AppLayout>
                  {IS_PRODUCTION && (
                    <GoogleTagManager
                      gtmId={GTM_TRACKING_ID}
                      dataLayerName={GTM_DATA_LAYER_NAME}
                    />
                  )}

                  <Component {...pageProps} err={err} />

                  <ReactQueryDevtools
                    initialIsOpen={false}
                    position="top-left"
                  />
                </AppLayout>
              </ExtendedFiltersProvider>
            </RouterHistoryProvider>
          </ToastProvider>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
