export default {
  add: 'Add product to library',
  aiTagsUpdate: 'AI Tags Update',
  all: 'All products',
  associatedAssets: 'Associated assets',
  attachSelected: 'Attach selected products',
  created: 'Created product',
  clearAllCustomTags: 'Clear all custom tags',
  customTags: 'Custom tags',
  download: 'Download product',
  downloadModel: 'Download model',
  deleteProduct: 'Delete product',
  deleteWarning:
    'Are you sure you want to delete the product? This action cannot be undone.',
  my: 'My Products',
  plural: 'Products',
  singular: 'Product',
  addProduct: 'Add product',
  attach: 'Attach products',
  attachProduct: 'Attach product',
  category: 'Product category',
  editProduct: 'Edit product',
  noPlural: 'No products',
  name: 'Product name',
  search: 'Search products',
  searchCustomTags: 'Search custom tags',
  searchProductsByNameOrSku: 'Search products by name or SKU',
  selected: 'Selected products',
  viewAll: 'View all products',
  categories: {
    homeDecor: 'Home decor',
    homeTextiles: 'Home textiles',
    exterior: 'Exterior',
    plants: 'Plants',
    kitchen: 'Kitchen'
  },
  oneOrMoreSkuWasNotFoundInProductLibrary:
    'One or more SKU was not found in product library'
};
