import { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import {
  FetchCategoriesScopes,
  FetchCategoriesSortTypes
} from '../../../main/categories/categoriesTypes';
import { MultiSelectDataType } from '../../MultiSelect/types';
import {
  MultiSelectFieldControlProps,
  MultiSelectFieldProps
} from '../MultiSelectField';

import {
  FetchProfileCategoriesQueryResponse,
  FETCH_PROFILE_CATEGORIES_QUERY
} from '../../../main/users/queries/fetchUserProfileCategories.query';

import { usePaginatedCategories } from '../../../main/categories/hooks/usePaginatedCategories';

import { CountryMultiSelectFieldControl } from '../CountriesSelectField/components/CountryMultiSelectFieldControl';
import { ProfileMultiSelectValueType } from '../../../main/teams/helpers/ProfileMultiSelectField/components/ProfileMultiSelectFieldControl';

import { CategoryCache } from '../../../main/categories/CategoryCache';

const initialFilters = {
  scope: [FetchCategoriesScopes.COUNTRIES],
  parentId: {
    isNull: true
  }
};

interface CountriesNameSelectFieldProps {
  withSecondNewAvPrefix?: boolean;
}

function CountriesNameSelectField<FormDataType>({
  control,
  withSecondNewAvPrefix,
  disabled,
  name,
  i18nLabel,
  i18nPlaceholder,
  labelClassName,
  error,
  defaultValue
}: CountriesNameSelectFieldProps &
  MultiSelectFieldControlProps<FormDataType> &
  MultiSelectFieldProps<FormDataType>) {
  const { categories: countries } =
    usePaginatedCategories<FetchProfileCategoriesQueryResponse>({
      query: FETCH_PROFILE_CATEGORIES_QUERY,
      cacheKey: CategoryCache.categoriesSelectFieldCacheKey(
        FetchCategoriesScopes.COUNTRIES
      ),
      initialFilters,
      initialLimit: 1000,
      initialSort: [FetchCategoriesSortTypes.LOCALIZED_NAME_ASC]
    });

  const countriesOptions = useMemo<MultiSelectDataType[]>(
    () =>
      countries.map((cat) => ({
        label: cat.localizedName,
        value: cat.localizedName
      })),
    [countries]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, name },
        fieldState: { error: fieldError }
      }) => (
        <div>
          <CountryMultiSelectFieldControl
            data={countriesOptions}
            defaultValue={defaultValue}
            disabled={disabled}
            error={fieldError?.message || error}
            i18nLabel={i18nLabel}
            i18nPlaceholder={i18nPlaceholder}
            labelClassName={labelClassName}
            multi={false}
            onChange={onChange}
            value={value as ProfileMultiSelectValueType}
            name={name}
            classNamePrefix={
              withSecondNewAvPrefix
                ? fieldError
                  ? 'second_new_av_error'
                  : 'second_new_av'
                : 'av'
            }
            inputWrapperClassName="w-full"
            isSearchable
          />
        </div>
      )}
    />
  );
}

export default CountriesNameSelectField;
