import { ClientError } from 'graphql-request';

import {
  ErrorMessage,
  HasNextPage,
  IsFetched,
  IsFetchingNextPage,
  IsPlaceholderData,
  UUID,
  PathUrl,
  ReadApiError
} from './fields';

export type FetchItemsFetched = IsFetched;
export type FetchItemsFetchingNextPage = IsFetchingNextPage;
export type FetchItemsIsPlaceholderData = IsPlaceholderData;
export type FetchItemsHasNextItemsPage = HasNextPage;
export type FetchItemsError = Error | ClientError;
export type FetchItemsErrorMessage = ErrorMessage;
export type BffApiFetchItemsError = ReadApiError;

export type FetchItemsPage = number;
export type FetchItemsLimit = number;
export type FetchItemsSerializer = string;
export type FetchItemsSortableField = string;
export type FetchItemsSort = FetchItemsSortableField[];
export type FetchItemsTotalCount = number;
export type FetchItemsFilterItems<FiltersType> = (
  nextFilters: FiltersType
) => void;
export type FetchItemsClearItemsFilters = () => void;
export type FetchItemsPaginateItems = (nextPage: FetchItemsPage) => void;
export type FetchItemsSortItems = (nextSort: FetchItemsSort) => void;
export type FetchItemsPrefetchItems = ({
  nextPage
}: {
  nextPage: FetchItemsPage;
}) => void;
export type FetchItemsLoadMoreItems = () => Promise<unknown>;

export type FetchItemsShowItem<ItemUUID = UUID> = (itemUuid: ItemUUID) => void;
export type FetchItemsEditItem<ItemUUID = UUID> = (itemUuid: ItemUUID) => void;

export type FetchItemsGqlQuery = string;

export type FetchItemsCacheKey = string;
export type FetchItemsCacheKeys = FetchItemsCacheKey[];

export type FetchItemsUrl = PathUrl;

export const enum IdFilterFields {
  EQ = 'eq',
  GT = 'gt',
  GTE = 'gte',
  IN = 'in',
  IS_NOT_NULL = 'isNotNull',
  IS_NULL = 'isNull',
  LT = 'lt?',
  LTE = 'lte',
  NOT_IN = 'notIn'
}
