import { gql } from 'graphql-request';

import {
  TeamAdditionalDiscount,
  TeamAddress,
  TeamCity,
  TeamClientStatus,
  TeamCompanyCrm,
  TeamCountryId,
  TeamCreatedAt,
  TeamCurrency,
  TeamDescription,
  TeamDiscount,
  TeamEmail,
  TeamFacebook,
  TeamFirstPaymentDate,
  TeamID,
  TeamImageFile,
  TeamNdaFileUUID,
  TeamNdaFileFile,
  TeamNdaFileName,
  TeamVendorFormUUID,
  TeamVendorFormFile,
  TeamVendorFormName,
  TeamAgreementUUID,
  TeamAgreementFile,
  TeamAgreementName,
  TeamInstagram,
  TeamLastPaymentDate,
  TeamLeadCreationDate,
  TeamLinkedIn,
  TeamManualTeamStatus,
  TeamName,
  TeamNanoID,
  TeamNda,
  TeamReferer,
  TeamSaleManagerID,
  TeamSaleManagerUUID,
  TeamSaleManagerNanoID,
  TeamRealDeclineReasons,
  TeamSite,
  TeamSocialNetworks,
  TeamStateID,
  TeamStateName,
  TeamTagsTagID,
  TeamTeamType,
  TeamFilesStorages,
  TeamTwitter,
  TeamUpdatedAt,
  TeamUpsalerID,
  TeamUpsalerUUID,
  TeamUpsalerNanoID,
  TeamUserID,
  TeamUUID,
  TeamValidateOutput,
  TeamZipCode,
  TeamBusinessEmail,
  TeamMarketingSourceID,
  TeamColdSourceID,
  TeamCategoryID,
  TeamSubCategoryID,
  TeamCompanyRevenueID,
  TeamNatureOfLeadID,
  TeamCompanySizeID,
  TeamRoleID,
  TeamLocale,
  TeamTimeZone,
  TeamTimeFormat,
  TeamDateFormat,
  TeamOperationHours,
  TeamPaymentMethodID,
  TeamSource,
  TeamRespondTime,
  TeamDeliveryTime,
  TeamHourlyRate,
  TeamRevisions,
  TeamViewPerTask,
  TeamWorkflowTypeID,
  TeamPriority,
  TeamComplexities,
  TeamScopeOfWorks,
  TeamUserFullName,
  TeamUserImageFile,
  TeamImageUUID,
  TeamUserNanoID,
  TeamOwnerUUID,
  TeamOwnerNanoID,
  TeamOwnerFullName,
  TeamOwnerID,
  TeamAccountReceivables,
  TeamDebtLimit,
  TeamDeferredPayment,
  TeamHoldRate,
  TeamTransactionFee,
  TeamProvisionForBadDebt,
  TeamProvisionForRevision,
  TeamTerms,
  TeamSubCategoriesSubCategoryID,
  TeamBlockedAt,
  TeamUserUUID,
  TeamContractorID,
  TeamContractorUUID,
  TeamContractorNanoID,
  TeamPreferredPaymentMethod,
  TeamOwnerFinanceRoleID,
  TeamOwnerFinanceRoleName,
  TeamAutoRepaymentEnabledAt
} from '../teamsTypes';

export interface FetchShowTeamQueryResponse {
  id: TeamID;
  uuid: TeamUUID;
  nanoId: TeamNanoID;
  name: TeamName;
  email: TeamEmail;
  businessEmail: TeamBusinessEmail;
  createdAt: TeamCreatedAt;
  blockedAt: TeamBlockedAt;
  autoRepaymentEnabledAt: TeamAutoRepaymentEnabledAt;
  clientStatus: TeamClientStatus;
  countryId: TeamCountryId;
  city: TeamCity;
  companyCrm: TeamCompanyCrm;
  coldSourceId: TeamColdSourceID;
  categoryId: TeamCategoryID;
  companyRevenueId: TeamCompanyRevenueID;
  companySizeId: TeamCompanySizeID;
  complexities: TeamComplexities;
  description: TeamDescription;
  site: TeamSite;
  stateId: TeamStateID;
  stateName: TeamStateName;
  subCategoryId: TeamSubCategoryID;
  subCategories: {
    id: TeamSubCategoriesSubCategoryID;
  }[];
  address: TeamAddress;
  facebook: TeamFacebook;
  linkedIn: TeamLinkedIn;
  twitter: TeamTwitter;
  instagram: TeamInstagram;
  socialNetworks: TeamSocialNetworks;
  manualTeamStatus: TeamManualTeamStatus;
  marketingSourceId: TeamMarketingSourceID;
  natureOfLeadId: TeamNatureOfLeadID;
  nda: TeamNda;
  firstPaymentDate: TeamFirstPaymentDate;
  lastPaymentDate: TeamLastPaymentDate;
  leadCreationDate: TeamLeadCreationDate;
  locale: TeamLocale;
  timeZone: TeamTimeZone;
  timeFormat: TeamTimeFormat;
  dateFormat: TeamDateFormat;
  operationHours: TeamOperationHours;
  discount: TeamDiscount;
  additionalDiscount: TeamAdditionalDiscount;
  realDeclineReasons: TeamRealDeclineReasons;
  currency: TeamCurrency;
  teamType: TeamTeamType;
  filesStorage: TeamFilesStorages;
  referer: TeamReferer;
  paymentMethods: {
    id: TeamPaymentMethodID;
  }[];
  roles: {
    id: TeamRoleID;
  }[];
  tags: {
    id: TeamTagsTagID;
  }[];
  image: {
    file: TeamImageFile;
  };
  ndaFile?: {
    uuid: TeamNdaFileUUID;
    file: TeamNdaFileFile;
    name: TeamNdaFileName;
  };
  vendorForm?: {
    uuid: TeamVendorFormUUID;
    file: TeamVendorFormFile;
    name: TeamVendorFormName;
  };
  agreement?: {
    uuid: TeamAgreementUUID;
    file: TeamAgreementFile;
    name: TeamAgreementName;
  };
  validateOutput: TeamValidateOutput;
  userId: TeamUserID;
  user: {
    id: TeamUserID;
    fullName: TeamUserFullName;
    nanoId: TeamUserNanoID;
    image: {
      uuid: TeamImageUUID;
      file: TeamUserImageFile;
      fileName: TeamUserImageFile;
    };
  };
  ownerId: TeamOwnerID;
  owner: {
    id: TeamOwnerID;
    uuid: TeamOwnerUUID;
    nanoId: TeamOwnerNanoID;
    fullName: TeamOwnerFullName;
    financeRoleName: TeamOwnerFinanceRoleName;
    financeRole: {
      id: TeamOwnerFinanceRoleID;
    };
  };
  upsalers: {
    id: TeamUpsalerID;
    uuid: TeamUpsalerUUID;
    nanoId: TeamUpsalerNanoID;
  }[];
  salesManagers: {
    id: TeamSaleManagerID;
    uuid: TeamSaleManagerUUID;
    nanoId: TeamSaleManagerNanoID;
  }[];
  scopeOfWorks: TeamScopeOfWorks;
  updatedAt: TeamUpdatedAt;
  zipCode: TeamZipCode;
  viewPerTask: TeamViewPerTask;
  revisions: TeamRevisions;
  hourlyRate: TeamHourlyRate;
  deliveryTime: TeamDeliveryTime;
  respondTime: TeamRespondTime;
  source: TeamSource;
  workflowTypeId: TeamWorkflowTypeID;
  priority: TeamPriority;
  accountReceivables: TeamAccountReceivables;
  debtLimit: TeamDebtLimit;
  deferredPayment: TeamDeferredPayment;
  holdRate: TeamHoldRate;
  transactionFee: TeamTransactionFee;
  preferredPaymentMethod: TeamPreferredPaymentMethod;
  provisionForBadDebt: TeamProvisionForBadDebt;
  provisionForRevision: TeamProvisionForRevision;
  terms: TeamTerms;
  users: {
    id: TeamUserID;
    uuid: TeamUserUUID;
    nanoId: TeamUserNanoID;
  }[];
  contractors: {
    id: TeamContractorID;
    uuid: TeamContractorUUID;
    nanoId: TeamContractorNanoID;
  }[];
}

export const FETCH_SHOW_TEAM_QUERY = gql`
  query ShowTeam($uuid: ID!) {
    team(uuid: $uuid) {
      id
      uuid
      nanoId
      name
      email
      businessEmail
      createdAt
      blockedAt
      autoRepaymentEnabledAt
      clientStatus
      countryId
      coldSourceId
      categoryId
      companyRevenueId
      companySizeId
      complexities {
        id
      }
      city
      companyCrm
      description
      site
      stateId
      stateName
      subCategoryId
      subCategories {
        id
      }
      address
      facebook
      linkedIn
      twitter
      instagram
      socialNetworks
      manualTeamStatus
      marketingSourceId
      natureOfLeadId
      nda
      firstPaymentDate
      lastPaymentDate
      leadCreationDate
      locale
      timeZone
      timeFormat
      dateFormat
      operationHours
      discount
      additionalDiscount
      preferredPaymentMethod
      realDeclineReasons {
        id
      }
      currency
      teamType
      filesStorage
      referer
      paymentMethods {
        id
      }
      roles {
        id
      }
      tags {
        id
      }
      image {
        file
      }
      ndaFile {
        uuid
        file
        name
      }
      vendorForm {
        uuid
        file
        name
      }
      agreement {
        uuid
        file
        name
      }
      validateOutput
      userId
      user {
        id
        nanoId
        fullName
        image {
          uuid
          file
          fileName
        }
      }
      ownerId
      owner {
        id
        uuid
        nanoId
        fullName
        financeRoleName
        financeRole {
          id
        }
      }
      upsalers {
        id
        uuid
        nanoId
      }
      salesManagers {
        id
        uuid
        nanoId
      }
      scopeOfWorks {
        id
      }
      updatedAt
      zipCode
      viewPerTask
      revisions
      hourlyRate
      deliveryTime
      respondTime
      source
      workflowTypeId
      priority
      accountReceivables
      debtLimit
      deferredPayment
      holdRate
      transactionFee
      provisionForBadDebt
      provisionForRevision
      terms
      users {
        id
        uuid
        nanoId
      }
      contractors {
        id
        uuid
        nanoId
      }
    }
  }
`;
