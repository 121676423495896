import {
  ID,
  UUID,
  CreatedAt,
  UpdatedAt,
  DateType,
  FileUrl,
  FetchItemsGqlQuery,
  FetchItemsCacheKey,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsIsPlaceholderData,
  FetchItemsFetchingNextPage,
  FetchItemsHasNextItemsPage,
  FetchItemsLimit,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsLoadMoreItems,
  FetchItemsTotalCount,
  UUIDFilter,
  BooleanFilter,
  FetchItemGqlQuery,
  CreateItemGqlQuery,
  UpdateItemGqlQuery,
  DeleteItemGqlQuery,
  CreateItemGqlStatus,
  UpdateItemGqlStatus,
  DeleteItemGqlStatus,
  ItemGqlError,
  FetchItemCacheKey,
  FetchItemsPage,
  FetchItemsShowItem,
  FetchItemsEditItem,
  DeviceNanoId,
  HexColor,
  NanoIDFilter,
  TextFilterType,
  FetchItemsFilterItems,
  UUIDsFilter,
  TagIDsFilter,
  DateFilterType,
  FetchItemsClearItemsFilters,
  CreateItemCacheKeys,
  IdFilterType,
  NanoID,
  UpdateItemIndexCacheKey,
  NanoIdFilterType,
  UuidFilterType,
  I18nText
} from '../../types';

import {
  BillID,
  BillUUID,
  BillAmount,
  BillCurrency,
  BillStatus,
  BillBody,
  BillCreatedAt,
  BillPayerUUID,
  BillPayerFullName,
  BillPayerPayoneerCheckoutAddress,
  BillPayable,
  BillPaymentForm,
  BillPaymentUrl,
  BillAccount,
  BillPayerNanoID,
  BillPayerClient,
  BillPayerCurrentTeamNanoID
} from '../bills/billsTypes';

import {
  ProjectID,
  ProjectName,
  ProjectNanoID,
  ProjectOwnerClient,
  ProjectOwnerID,
  ProjectTeamName,
  ProjectTeamNanoID,
  ProjectUUID
} from '../projects/projectsTypes';

import {
  TaskID,
  TaskMark,
  TaskName,
  TaskNanoID,
  TaskStatus,
  TaskSupportMark,
  TaskUUID
} from '../tasks/tasksTypes';

import {
  UserUUID,
  UserClient,
  UserFullName,
  UserImageUUID,
  UserImageFile,
  UserNanoID,
  UserID,
  UserCurrentTeamNanoID
} from '../users/usersTypes';

import {
  FileAttachmentCreatedAt,
  FileAttachmentDrawing,
  FileAttachmentExt,
  FileAttachmentFile,
  FileAttachmentID,
  FileAttachmentName,
  FileAttachmentReference,
  FileAttachmentSize,
  FileAttachmentTexture,
  FileAttachmentType,
  FileAttachmentUUID,
  FileAttachmentWidth,
  FileAttachmentHeight,
  FileAttachmentDPI,
  FileAttachmentWhiteboardKeyID
} from '../fileAttachments/fileAttachmentsTypes';

import {
  SelectedProductID,
  SelectedProductProductBrand,
  SelectedProductProductName,
  SelectedProductProductNda,
  SelectedProductUUID
} from '../selectedProducts/selectedProductsTypes';

import {
  SelectedLifestyleID,
  SelectedLifestyleLifestyleAuthorLocalizedName,
  SelectedLifestyleLifestyleCategoryLocalizedName,
  SelectedLifestyleLifestyleName,
  SelectedLifestyleLifestyleNda,
  SelectedLifestyleLifestylePremiumContentAt,
  SelectedLifestyleUUID
} from '../selectedLifestyles/selectedLifestylesTypes';

import {
  SelectedMaterialID,
  SelectedMaterialMaterialName,
  SelectedMaterialMaterialNda,
  SelectedMaterialMaterialBrand,
  SelectedMaterialUUID
} from '../selectedMaterials/selectedMaterialsTypes';

import {
  InvoiceAmount,
  InvoiceCreatedAt,
  InvoiceDueDate,
  InvoiceID,
  InvoiceInvoiceType,
  InvoiceNanoID,
  InvoicePaymentMethods,
  InvoicePrepayment,
  InvoicePrepaymentAmount,
  InvoiceReturnReasons,
  InvoiceStatus,
  InvoiceTotal,
  InvoiceUpdatedAt,
  InvoiceUUID,
  FetchInvoicesFetched,
  FetchInvoicesErrorMessage
} from '../invoices/invoicesTypes';

import { TeamNanoID } from '../teams/teamsTypes';
import {
  UpdateItemsCacheKeys,
  UpdateItemsGqlError,
  UpdateItemsGqlQuery,
  UpdateItemsGqlStatus,
  UpdateItemsIndexCacheKey
} from '../../types/updateItemsTypes';
import {
  TodoItemDone,
  TodoItemID,
  TodoItemName,
  TodoItemOrder,
  TodoItemUUID,
  TodoItemVisibleForClients
} from '../todoItems/todoItemsTypes';
import { WhiteboardKeyID } from '../whiteboards/whiteboardsTypes';
import { ProductID } from '../products/productsTypes';

export type MessageID = ID;
export type MessageIDs = MessageID[];
export type MessageUUID = UUID;
export type MessageNanoId = NanoID;
export type MessageBody = string;
export type MessageMentionedUserID = UserID;
export type MessageMentionedUserUUID = UserUUID;
export type MessageMentionedUserNanoID = UserNanoID;
export type MessageMentionedUserFullName = MessageUserFullName;
export type MessageMentionedUserIDs = MessageMentionedUserID[];
export type MessageMuted = boolean;
export type MessageMutedForClientsAt = DateType;
export type MessageAiNotRelevantAt = DateType;
export type MessageHours = number;
export type MessageMinutes = number;
export type MessageMessageType = MessageMessageTypes;
export type MessageFavorite = boolean;
export type MessageFlagged = boolean;
export type MessageRead = boolean;
export type MessageRed = boolean;
export type MessagePinnedAt = DateType;
export type MessageVisibleForClient = boolean;
export type MessageColors = HexColor[];
export type MessageCreatedAt = CreatedAt;
export type MessageUpdatedAt = UpdatedAt;
export type MessageDeviceNanoId = DeviceNanoId;
export type MessageIsResult = boolean;
export type MessageResultVersion = number;
export type MessageResentAt = DateType;
export type MessagesResendOnlyClients = boolean;
export type MessageInvoiceID = InvoiceID;
export type MessageInvoiceUUID = InvoiceUUID;
export type MessageInvoiceNanoID = InvoiceNanoID;
export type MessageInvoiceAmount = InvoiceAmount;
export type MessageInvoiceClientID = ID;
export type MessageInvoiceClientStatus = string;
export type MessageInvoicePrepayment = InvoicePrepayment;
export type MessageInvoicePrepaymentAmount = InvoicePrepaymentAmount;
export type MessageInvoiceReturnReason = InvoiceReturnReasons;
export type MessageInvoiceStatus = InvoiceStatus;
export type MessageInvoiceCreatedAt = InvoiceCreatedAt;
export type MessageInvoiceDueDate = InvoiceDueDate;
export type MessageInvoicePaymentMethod = InvoicePaymentMethods;
export type MessageInvoiceUpdatedAt = InvoiceUpdatedAt;
export type MessageInvoiceType = InvoiceInvoiceType;
export type MessageInvoiceTotal = InvoiceTotal;
export type MessageInvoiceFetched = FetchInvoicesFetched;
export type MessageInvoiceErrorMessage = FetchInvoicesErrorMessage;
export type MessageWorkOnProject = boolean;
export type MessageWhiteboardKeyID = WhiteboardKeyID;
export type MessageThreadStart = boolean;
export type MessageThreadKeyID = string;
export type MessageThreadKeyIDs = MessageThreadKeyID[];
export type MessageSortType = MessageSortTypeTypes;
export type MessageClassification = MessageClassifications;

export enum MessageClassifications {
  HAPPY = 'happy',
  UNHAPPY = 'unhappy',
  MOST_LIKELY_UNHAPPY = 'most likely unhappy',
  MOST_LIKELY_HAPPY = 'most likely happy',
  NEUTRAL = 'neutral'
}

export enum MessageMessageTypes {
  CLIENT_DESCRIPTION = 'client_description',
  DISCUSSION_MESSAGE = 'discussion_message',
  ADDED_FACILITIES = 'added_facilities',
  TRACK_TIME = 'track_time',
  DISCUSSION_BODY = 'discussion_body',
  BILL_BUTTON = 'bill_button',
  PAID_MESSAGE = 'paid_message',
  ADDED_FILE_ATTACHMENTS = 'added_file_attachments',
  ADDED_SCENES = 'added_scenes',
  RAIFFEISEN_BANK_BUTTON = 'raiffeisen_bank_button',
  RESULTS = 'results',
  TWOCHECKOUT_BUTTON = 'twocheckout_button',
  PAYONEER_BUTTON = 'payoneer_button',
  TASK_DESCRIPTION = 'task_description',
  RIETUMU_BUTTON = 'rietumu_button',
  UPDATED_CLIENT_DESCRIPTION = 'updated_client_description',
  PAYMENT_PROCESSING = 'payment_processing',
  DESTROYED_TODO_ITEM = 'destroyed_todo_item',
  CREATED_TODO_ITEM = 'created_todo_item',
  CHANGED_TODO_ITEM_DONE = 'changed_todo_item_done',
  CHANGED_TODO_ITEM_NAME = 'changed_todo_item_name',
  LIQPAY_BUTTON = 'liqpay_button',
  CANCEL_PAID_MESSAGE = 'cancel_paid_message',
  UPDATED_TASK_DESCRIPTION = 'updated_task_description',
  UPDATED_DISCUSSION_BODY = 'updated_discussion_body',
  INTERKASSA_BUTTON = 'interkassa_button',
  CLUSTER_FOR_REVISION = 'cluster_for_revision',
  FONDY_BUTTON = 'fondy_button',
  MISSING_SENDER = 'missing_sender',
  INVOICE_BUTTON = 'invoice_button',
  EASYPAY_BUTTON = 'easypay_button',
  STRIPE_BUTTON = 'stripe_button',
  EWAY_BUTTON = 'eway_button',
  FRONTEND_SENDING_MESSAGE = 'frontend_sending_message',
  INVOICE_MESSAGE = 'invoice_message',
  PAID_INVOICE_MESSAGE = 'paid_invoice_message',
  REFUND_INVOICE_MESSAGE = 'refund_invoice_message',
  CHARGEBACK_INVOICE_MESSAGE = 'chargeback_invoice_message',
  PAYMENT_PROCESSING_INVOICE_MESSAGE = 'payment_processing_invoice_message',
  VERSION = 'version'
}

export type MessageUserUUID = UserUUID;
export type MessageUserID = UserID;
export type MessageUserNanoID = UserNanoID;
export type MessageUserFullName = UserFullName;
export type MessageUserClient = UserClient;
export type MessageUserImageUUID = UserImageUUID;
export type MessageUserImageFile = UserImageFile;
export type MessageUserUserCurrentTeamNanoID = UserCurrentTeamNanoID;

export type MessageProjectID = ProjectID;
export type MessageProjectUUID = ProjectUUID;
export type MessageProjectNanoID = ProjectNanoID;
export type MessageProjectName = ProjectName;
export type MessageProjectUserID = UserID;
export type MessageProjectOwnerID = ProjectOwnerID;
export type MessageProjectOwnerClient = ProjectOwnerClient;
export type MessageProjectClient = UserClient;
export type MessageProjectTeamNanoID = ProjectTeamNanoID;
export type MessageProjectTeamName = ProjectTeamName;
export type MessageTaskID = TaskID;
export type MessageTaskUUID = TaskUUID;
export type MessageTaskNanoID = TaskNanoID;
export type MessageTaskName = TaskName;
export type MessageTaskStatus = TaskStatus;
export type MessageTaskMark = TaskMark;
export type MessageTaskSupportMark = TaskSupportMark;
export type MessageTaskProjectNanoID = ProjectNanoID;
export type MessageTaskProjectUUID = ProjectUUID;

export type MessageBillID = BillID;
export type MessageBillUUID = BillUUID;
export type MessageBillAccount = BillAccount;
export type MessageBillAmount = BillAmount;
export type MessageBillCurrency = BillCurrency;
export type MessageBillStatus = BillStatus;
export type MessageBillBody = BillBody;
export type MessageBillPayable = BillPayable;
export type MessageBillCreatedAt = BillCreatedAt;
export type MessageBillPayerUUID = BillPayerUUID;
export type MessageBillPayerNanoID = BillPayerNanoID;
export type MessageBillPayerFullName = BillPayerFullName;
export type MessageBillPaymentForm = BillPaymentForm;
export type MessageBillPaymentUrl = BillPaymentUrl;
export type MessageBillPayerPayoneerCheckoutAddress =
  BillPayerPayoneerCheckoutAddress;
export type MessageBillPayerClient = BillPayerClient;
export type MessageBillPayerCurrentTeamNanoID = BillPayerCurrentTeamNanoID;

export type MessagePaidBillID = BillID;
export type MessagePaidBillUUID = BillUUID;
export type MessagePaidBillAmount = BillAmount;
export type MessagePaidBillCurrency = BillCurrency;
export type MessagePaidBillPayerUUID = BillPayerUUID;
export type MessagePaidBillPayerNanoID = BillPayerNanoID;
export type MessagePaidBillPayerFullName = BillPayerFullName;

export type MessageFileAttachmentID = FileAttachmentID;
export type MessageFileAttachmentIDs = MessageFileAttachmentID[];
export type MessageFileAttachmentsUUID = FileAttachmentUUID;
export type MessageOldFileAttachmentsType = FileAttachmentType;
export type MessageFileAttachmentsName = FileAttachmentName;
export type MessageFileAttachmentsFile = FileAttachmentFile;
export type MessageFileAttachmentsSize = FileAttachmentSize;
export type MessageFileAttachmentHeight = FileAttachmentHeight;
export type MessageFileAttachmentWidth = FileAttachmentWidth;
export type MessageFileAttachmentsDrawing = FileAttachmentDrawing;
export type MessageFileAttachmentsReference = FileAttachmentReference;
export type MessageFileAttachmentsTexture = FileAttachmentTexture;
export type MessageFileAttachmentsCreatedAt = FileAttachmentCreatedAt;
export type MessageFileAttachmentsExt = FileAttachmentExt;
export type MessageFileAttachmentsWidth = FileAttachmentWidth;
export type MessageFileAttachmentsHeight = FileAttachmentHeight;
export type MessageFileAttachmentsDPI = FileAttachmentDPI;
export type MessageFileAttachmentsWhiteboardKeyID =
  FileAttachmentWhiteboardKeyID;

export type MessageTodoItemID = TodoItemID;
export type MessageTodoItemUUID = TodoItemUUID;
export type MessageTodoItemName = TodoItemName;
export type MessageTodoItemDone = TodoItemDone;
export type MessageTodoItemOrder = TodoItemOrder;
export type MessageTodoItemVisibleForClients = TodoItemVisibleForClients;

export type MessageCreatedProductID = ProductID;

export type MessageFileAttachment = {
  id: MessageFileAttachmentID;
  uuid: MessageFileAttachmentsUUID;
  type: MessageFileAttachmentsType;
  name: MessageFileAttachmentsName;
  file: MessageFileAttachmentsFile;
  size: MessageFileAttachmentsSize;
  drawing: MessageFileAttachmentsDrawing;
  reference: MessageFileAttachmentsReference;
  texture: MessageFileAttachmentsTexture;
  createdAt: MessageFileAttachmentsCreatedAt;
  ext: MessageFileAttachmentsExt;
  width: MessageFileAttachmentsWidth;
  height: MessageFileAttachmentsHeight;
  dpi: MessageFileAttachmentsDPI;
};

export type MessageFileAttachments = MessageFileAttachment[];

export type MessageSelectedProductID = SelectedProductID;
export type MessageSelectedProductIDs = MessageSelectedProductID[];
export type MessageSelectedProductsUUID = SelectedProductUUID;
export type MessageOldSelectedProductsType = 'selected_product';
export type MessageSelectedProductsCreatedAt = CreatedAt;
export type MessageSelectedProductsProductUUID = UUID;
export type MessageSelectedProductsProductImageUUID = UUID;
export type MessageSelectedProductsProductImageName = string;
export type MessageSelectedProductsProductName = SelectedProductProductName;
export type MessageSelectedProductsProductNda = SelectedProductProductNda;
export type MessageSelectedProductsProductBrand = SelectedProductProductBrand;
export type MessageSelectedProductsProductImageFile = FileUrl;
export type MessageSelectedLifestyleID = SelectedLifestyleID;
export type MessageSelectedLifestyleIDs = MessageSelectedLifestyleID[];
export type MessageSelectedLifestylesUUID = SelectedLifestyleUUID;
export type MessageOldSelectedLifestylesType = 'selected_lifestyle';
export type MessageSelectedLifestylesCreatedAt = CreatedAt;
export type MessageSelectedLifestylesLifestyleUUID = UUID;
export type MessageSelectedLifestylesLifestyleName =
  SelectedLifestyleLifestyleName;
export type MessageSelectedLifestylesLifestyleNda =
  SelectedLifestyleLifestyleNda;
export type MessageSelectedLifestylesLifestylePremiumContentAt =
  SelectedLifestyleLifestylePremiumContentAt;
export type MessageSelectedLifestylesLifestyleAuthorLocalizedName =
  SelectedLifestyleLifestyleAuthorLocalizedName;
export type MessageSelectedLifestylesLifestyleCategoryLocalizedName =
  SelectedLifestyleLifestyleCategoryLocalizedName;
export type MessageSelectedLifestylesLifestyleImageUUID = UUID;
export type MessageSelectedLifestylesLifestyleImageName = string;
export type MessageSelectedLifestylesLifestyleImageFile = FileUrl;
export type MessageSelectedMaterialID = SelectedMaterialID;
export type MessageSelectedMaterialIDs = MessageSelectedMaterialID[];
export type MessageSelectedMaterialsUUID = SelectedMaterialUUID;
export type MessageOldSelectedMaterialsType = 'selected_material';
export type MessageSelectedMaterialsCreatedAt = CreatedAt;
export type MessageSelectedMaterialsMaterialUUID = UUID;
export type MessageSelectedMaterialsMaterialNda = SelectedMaterialMaterialNda;
export type MessageSelectedMaterialsMaterialBrand =
  SelectedMaterialMaterialBrand;
export type MessageSelectedMaterialsMaterialName = SelectedMaterialMaterialName;
export type MessageSelectedMaterialsMaterialImageUUID = UUID;
export type MessageSelectedMaterialsMaterialImageName = string;
export type MessageSelectedMaterialsMaterialImageFile = FileUrl;

export type MessageLikeUUID = UUID;
export type MessageLikeType = string;
export type MessageLikeCount = number;
export type MessageLikeEmojiText = string;
export type MessageLikeUserID = UserID;
export type MessageLikeUserUUID = UserImageUUID;
export type MessageLikeUserFullName = UserFullName;
export type MessageLikeUserImageFile = UserImageFile;
export type MessageLikeUserImage = {
  file: MessageLikeUserImageFile;
};
export type MessageLikeUser = {
  uuid: MessageLikeUserUUID;
  fullName: MessageLikeUserFullName;
  image: MessageLikeUserImage | null;
};
export type MessageLikeUsers = MessageLikeUser[];
export type MessageLikeItem = {
  uuid: MessageLikeUUID;
  messageLikeType: MessageLikeType;
  userId: MessageLikeUserID;
  user: MessageLikeUser;
};
export type MessageLikeItems = MessageLikeItem[];

export type MessageForwardedMessageProjectNanoID = MessageProjectNanoID;
export type MessageForwardedMessageProjectName = MessageProjectName;
export type MessageForwardedMessageTaskNanoID = MessageTaskNanoID;
export type MessageForwardedMessageTaskName = MessageTaskName;

export type MessageBodyFilter = TextFilterType;
export type MessageCreatedAtFilter = DateFilterType;
export type MessageFavoriteFilter = BooleanFilter;
export type MessageFlaggedFilter = BooleanFilter;
export type MessageIdsFilter = IdFilterType;
export type MessageTypeFilter = MessageMessageType;
export type MessageProjectMemberFilter = BooleanFilter;
export type MessageTagIdsFilter = TagIDsFilter;
export type MessageTaskMemberFilter = BooleanFilter;
export type MessageThreadKeyIdFilter = TextFilterType;
export type MessageThreadStartFilter = BooleanFilter;
export type MessageUnreadFilter = BooleanFilter;
export type MessageReadFilter = BooleanFilter;
export type MessageUserUUIDsFilter = UUIDsFilter;
export type MessageVisibleForClientFilter = BooleanFilter;
export type MessageWhiteboardKeyIdFilter = TextFilterType;
export type MessageWithAttachmentsFilter = BooleanFilter;
export type MessageWithHoursOrMinutesFilter = BooleanFilter;
export type MessageWithoutHoursAndMinutesFilter = BooleanFilter;

export type MessageFinFileAttachmentsType = 'FileAttachment';
export type MessageFinSelectedProductsType = 'SelectedProduct';
export type MessageFinSelectedLifestylesType = 'SelectedLifestyle';
export type MessageFinSelectedMaterialsType = 'SelectedMaterial';

export type MessageFileAttachmentsType =
  | MessageFinFileAttachmentsType
  | MessageOldFileAttachmentsType;
export type MessageSelectedProductsType =
  | MessageFinSelectedProductsType
  | MessageOldSelectedProductsType;
export type MessageSelectedLifestylesType =
  | MessageFinSelectedLifestylesType
  | MessageOldSelectedLifestylesType;
export type MessageSelectedMaterialsType =
  | MessageFinSelectedMaterialsType
  | MessageOldSelectedMaterialsType;

export const enum MessageFinAttachmentTypes {
  FILE_ATTACHMENT = 'FileAttachment',
  SELECTED_PRODUCT = 'SelectedProduct',
  SELECTED_LIFESTYLE = 'SelectedLifestyle',
  SELECTED_MATERIAL = 'SelectedMaterial'
}

export const enum MessageAttachmentTypes {
  FILE_ATTACHMENT = 'file_attachment',
  SELECTED_PRODUCT = 'selected_product',
  SELECTED_LIFESTYLE = 'selected_lifestyle',
  SELECTED_MATERIAL = 'selected_material'
}

export const enum MessageFields {
  ID = 'id',
  UUID = 'uuid',
  BODY = 'body',
  LEXICAL = 'lexical',
  VISIBLE_FOR_CLIENT = 'visibleForClient',
  FILE_ATTACHMENT_IDS = 'fileAttachmentIds',
  ATTACHED_FILE_ATTACHMENT_IDS = 'attachedFileAttachmentIds',
  SELECTED_PRODUCT_IDS = 'selectedProductIds',
  SELECTED_LIFESTYLE_IDS = 'selectedLifestyleIds',
  SELECTED_MATERIAL_IDS = 'selectedMaterialIds',
  COLORS = 'colors',
  CREATED_AT = 'createdAt',
  REPLIED_MESSAGE_ID = 'repliedMessageId'
}

export const enum FetchMessagesFilterFields {
  ID = 'id',
  BODY = 'body',
  CREATED_AT = 'createdAt',
  FAVORITE = 'favorite',
  FLAGGED = 'flagged',
  ONLY_CLIENTS = 'onlyClients',
  PROJECT_MEMBER = 'projectMember',
  TAG_IDS = 'tagIds',
  TASK_MEMBER = 'taskMember',
  READ = 'read',
  UNREAD = 'unread',
  USER_UUIDS = 'userUuids',
  VISIBLE_FOR_CLIENT = 'visibleForClient',
  WITH_ATTACHMENTS = 'withAttachments'
}

export const enum FetchFinMessagesFilterFields {
  ID = 'id',
  BODY = 'body',
  CREATED_AT = 'createdAt',
  FAVORITE = 'favorite',
  FLAGGED = 'flagged',
  ONLY_CLIENTS = 'onlyClients',
  PROJECT_MEMBER = 'project.member',
  TAG_IDS = 'tagId.in',
  TASK_MEMBER = 'task.member',
  READ = 'read',
  UNREAD = 'unread',
  USER_IDS = 'userId.in',
  VISIBLE_FOR_CLIENT = 'visibleForClient',
  WITH_ATTACHMENTS = 'withAttachments'
}

export const enum ForwardMessageFields {
  UUID = 'uuid',
  BODY = 'body',
  VISIBLE_FOR_CLIENT = 'visibleForClient',
  INCLUDES_ATTACHMENTS = 'includesAttachments',
  PROJECT_UUID = 'projectUuid',
  TASK_UUIDS = 'taskUuids'
}

export const enum BatchForwardMessagesFields {
  UUID = 'uuid',
  BODY = 'body',
  VISIBLE_FOR_CLIENT = 'visibleForClient',
  INCLUDES_ATTACHMENTS = 'includesAttachments',
  PROJECT_UUID = 'projectUuid',
  TASK_UUIDS = 'taskUuids',
  MESSAGES = 'messages'
}

export interface FetchMessagesFilters {
  body?: MessageBodyFilter;
  createdAt?: MessageCreatedAtFilter;
  favorite?: MessageFavoriteFilter;
  flagged?: MessageFlaggedFilter;
  flaggedOrPinned?: BooleanFilter;
  id?: MessageIdsFilter;
  isResult?: BooleanFilter;
  messageType?: MessageTypeFilter;
  onlyClients?: BooleanFilter;
  pinned?: BooleanFilter;
  projectMember?: MessageProjectMemberFilter;
  projectNanoId?: NanoIDFilter;
  projectUuid?: UUIDFilter;
  tagIds?: MessageTagIdsFilter;
  taskMember?: MessageTaskMemberFilter;
  taskNanoId?: NanoIDFilter;
  taskUuid?: UUIDFilter;
  teamNanoIds?: TeamNanoID[];
  threadKeyId?: MessageThreadKeyIdFilter;
  threadStart?: MessageThreadStartFilter;
  unread?: MessageUnreadFilter;
  read?: MessageReadFilter;
  userUuids?: MessageUserUUIDsFilter;
  visibleForClient?: MessageVisibleForClientFilter;
  whiteboardKeyId?: MessageWhiteboardKeyIdFilter;
  withAttachments?: MessageWithAttachmentsFilter;
  withHoursOrMinutes?: MessageWithHoursOrMinutesFilter;
  withoutHoursAndMinutes?: MessageWithoutHoursAndMinutesFilter;

  project?: {
    nanoId?: NanoIdFilterType;
    uuid?: UuidFilterType;
    member?: BooleanFilter;
  };
  tagId?: IdFilterType;
  task?: {
    nanoId?: NanoIdFilterType;
    uuid?: UuidFilterType;
    member?: BooleanFilter;
  };
  userId?: IdFilterType;
}

export interface FetchFinMessagesFilters {
  body?: MessageBodyFilter;
  createdAt?: MessageCreatedAtFilter;
  favorite?: MessageFavoriteFilter;
  flagged?: MessageFlaggedFilter;
  flaggedOrPinned?: BooleanFilter;
  id?: MessageIdsFilter;
  isResult?: BooleanFilter;
  messageType?: TextFilterType;
  onlyClients?: BooleanFilter;
  pinned?: BooleanFilter;
  project?: {
    nanoId?: NanoIdFilterType;
    uuid?: UuidFilterType;
    member?: BooleanFilter;
  };
  tagId?: IdFilterType;
  task?: {
    nanoId?: NanoIdFilterType;
    uuid?: UuidFilterType;
    member?: BooleanFilter;
  };
  teamNanoId?: NanoIdFilterType;
  threadKeyId?: MessageThreadKeyIdFilter;
  threadStart?: MessageThreadStartFilter;
  unread?: MessageUnreadFilter;
  userUuid?: UuidFilterType;
  userId?: IdFilterType;
  userNanoId?: NanoIdFilterType;
  visibleForClient?: MessageVisibleForClientFilter;
  whiteboardKeyId?: MessageWhiteboardKeyIdFilter;
  withAttachments?: MessageWithAttachmentsFilter;
  withHoursOrMinutes?: MessageWithHoursOrMinutesFilter;
  withoutHoursAndMinutes?: MessageWithoutHoursAndMinutesFilter;
  withAttached?: {
    attachmentTypes: FetchFinMessagesFiltersWithAttachedAttachmentTypes;
    set: FetchFinMessagesFiltersWithAttachedLogicalOperator;
  };
}

export const enum FetchFinMessagesFiltersWithAttachedLogicalOperator {
  ALL = 'all',
  ANY = 'any',
  NONE = 'none'
}

export interface FetchFinMessagesFiltersWithAttachedAttachmentTypes {
  colors?: BooleanFilter;
  fileAttachments?: BooleanFilter;
  selectedLifestyles?: BooleanFilter;
  selectedMaterials?: BooleanFilter;
  selectedProducts?: BooleanFilter;
  todoItems?: BooleanFilter;
}

export const enum FetchMessagesSortableFields {
  ID = 'id',
  CREATED_AT = 'createdAt'
}

export const enum FetchMessagesSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export const enum MessageSortTypeTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  SIZE_ASC = 'SIZE_ASC',
  SIZE_DESC = 'SIZE_DESC'
}

export type FetchMessagesFetched = FetchItemsFetched;
export type FetchMessagesErrorMessage = FetchItemsErrorMessage;
export type FetchMessagesIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchMessagesFetchingNextPage = FetchItemsFetchingNextPage;
export type FetchMessagesHasNextMessagesPage = FetchItemsHasNextItemsPage;
export type FetchMessagesPage = FetchItemsPage;
export type FetchMessagesLimit = FetchItemsLimit;
export type FetchMessagesSort = FetchItemsSort; // FetchMessagesSortTypes[];
export type FetchMessagesTotalCount = FetchItemsTotalCount;
export type FetchMessagesSortMessages = FetchItemsSortItems;
export type FetchMessagesPaginateMessages = FetchItemsPaginateItems;
export type FetchMessagesPrefetchMessages = FetchItemsPrefetchItems;
export type FetchMessagesLoadMoreMessages = FetchItemsLoadMoreItems;
export type FetchMessagesShowMessage = FetchItemsShowItem;
export type FetchMessagesEditMessage = FetchItemsEditItem;

export type FetchMessagesGqlQuery = FetchItemsGqlQuery;

export type FetchMessageCacheKey = FetchItemCacheKey;
export type FetchMessagesCacheKey = FetchItemsCacheKey;
export type ForwardMessageIndexCacheKey = UpdateItemIndexCacheKey;
export type ForwardMessageThreadsIndexCacheKey = UpdateItemsIndexCacheKey;

export type FetchMessageGqlQuery = FetchItemGqlQuery;
export type CreateMessageInProjectGqlQuery = CreateItemGqlQuery;
export type CreateMessageInTaskGqlQuery = CreateItemGqlQuery;
export type CreateMessageWithTaskNanoIdGqlQuery = CreateItemGqlQuery;
export type CreateMessageWithProjectNanoIdGqlQuery = CreateItemGqlQuery;
export type UpdateMessageGqlQuery = UpdateItemGqlQuery;
export type DeleteMessageGqlQuery = DeleteItemGqlQuery;
export type DownloadMessageAttachmentsGqlQuery = UpdateItemGqlQuery;
export type DownloadMessageFileAttachmentsGqlQuery = UpdateItemGqlQuery;
export type DownloadMessageSelectedProductsGqlQuery = UpdateItemGqlQuery;
export type DownloadMessageSelectedLifestylesGqlQuery = UpdateItemGqlQuery;
export type ForwardMessageGqlQuery = UpdateItemGqlQuery;
export type ForwardMessageThreadsGqlQuery = UpdateItemsGqlQuery;
export type AddToDescriptionMessageGqlQuery = UpdateItemGqlQuery;

export type CreateMessageInProjectGqlStatus = CreateItemGqlStatus;
export type CreateMessageInTaskGqlStatus = CreateItemGqlStatus;
export type CreateMessageWithTaskNanoIdGqlStatus = CreateItemGqlStatus;
export type CreateMessageWithProjectNanoIdGqlStatus = CreateItemGqlStatus;
export type UpdateMessageGqlStatus = UpdateItemGqlStatus;
export type DeleteMessageGqlStatus = DeleteItemGqlStatus;
export type DownloadMessageAttachmentsGqlStatus = UpdateItemGqlStatus;
export type DownloadMessageFileAttachmentsGqlStatus = UpdateItemGqlStatus;
export type DownloadMessageSelectedProductsGqlStatus = UpdateItemGqlStatus;
export type DownloadMessageSelectedLifestylesGqlStatus = UpdateItemGqlStatus;
export type ForwardMessageGqlStatus = UpdateItemGqlStatus;
export type ForwardMessageThreadsGqlStatus = UpdateItemsGqlStatus;
export type AddToDescriptionMessageGqlStatus = UpdateItemGqlStatus;
export type MessageGqlError = ItemGqlError;

export type FetchMessagesInProjectCacheKey = FetchItemsCacheKey;
export type FetchMessagesInTaskCacheKey = FetchItemsCacheKey;
export type FetchMessageInProjectCacheKey = FetchItemCacheKey;
export type FetchMessageInTaskCacheKey = FetchItemCacheKey;

export type ToggleMessageLikeGqlStatus = UpdateItemGqlStatus;
export type FetchMessagesFilterMessages =
  FetchItemsFilterItems<FetchMessagesFilters>;

export type FetchFinMessagesFilterMessages =
  FetchItemsFilterItems<FetchFinMessagesFilters>;
export type FetchMessagesClearMessagesFilters = FetchItemsClearItemsFilters;

export type CreateMessageWithTaskNanoIdCacheKeys = CreateItemCacheKeys;
export type CreateMessageWithProjectNanoIdCacheKeys = CreateItemCacheKeys;
export type CreateMessageFromAiProductsSearchCacheKeys = CreateItemCacheKeys;
export type CreateMessageFromAiProductsSearchByAngleCacheKeys =
  CreateItemCacheKeys;
export type CreateMessageFromAiDetectProductsSearchCacheKeys =
  CreateItemCacheKeys;

export type OnEditMessageAction<T = unknown> = (message: T) => void;

export type UnmuteMessagesThreadCacheKeys = UpdateItemsCacheKeys;
export type UnmuteMessagesThreadGqlError = UpdateItemsGqlError;
export type UnmuteMessagesThreadGqlStatus = UpdateItemsGqlStatus;

export type MessagesSettingsType = {
  displayTime: number;
  withWhiteboardMessages: boolean;
  autoPinMessageWithTodoItem: boolean;
};

export const enum MessageCommandTypesEnum {
  CHAT_GPT = 'chat_gpt',
  INSERT_TEXT = 'insert_text'
}

export const enum MessageCommandsEnum {
  CASUAL_TRANSLATE = 'casual_translate',
  CHAT_GPT = 'chat_gpt',
  CHECK_GRAMMAR = 'check_grammar',
  FRIENDLY_TRANSLATE = 'friendly_translate',
  THANKS_A_LOT = 'thanks_a_lot',
  TODO_LIST_FROM_PDF = 'todo_list_from_pdf',
  TRANSLATE = 'translate'
}

export type MessageCommandsDataValueType = {
  i18nDescription: I18nText;
  i18nTitle: I18nText;
  i18nUserContent?: I18nText | null;
  systemContent?: string | null;
  type: MessageCommandTypesEnum;
};

export type MessageCommandsDataType = Record<
  MessageCommandsEnum,
  MessageCommandsDataValueType
>;
