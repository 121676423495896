import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface MaterialsKeyType {
  attachSelected: LocalesKey;
  download: LocalesKey;
  my: LocalesKey;
  plural: LocalesKey;
  singular: LocalesKey;
  search: LocalesKey;
  searchMaterialsByName: LocalesKey;
  addMaterial: LocalesKey;
  attach: LocalesKey;
  attachMaterial: LocalesKey;
  all: LocalesKey;
  deleteMaterial: LocalesKey;
  deleteWarning: LocalesKey;
  category: LocalesKey;
  editMaterial: LocalesKey;
  name: LocalesKey;
  selected: LocalesKey;
  viewAll: LocalesKey;
  oneOrMoreSkuWasNotFoundInMaterialLibrary: LocalesKey;
}

export const materialsKeys = keyPathMirror<MaterialsKeyType, string>(
  {
    attachSelected: null,
    download: null,
    my: null,
    plural: null,
    singular: null,
    search: null,
    searchMaterialsByName: null,
    addMaterial: null,
    attach: null,
    attachMaterial: null,
    all: null,
    deleteMaterial: null,
    deleteWarning: null,
    category: null,
    editMaterial: null,
    name: null,
    selected: null,
    viewAll: null,
    oneOrMoreSkuWasNotFoundInMaterialLibrary: null
  },
  'models.materials.'
);
