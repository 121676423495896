import { request } from 'graphql-request';
import get from 'lodash/get';
import includes from 'lodash/includes';

import { GRAPHQL_API_URI } from '../../../../../../../config';

import { UpdateItemGqlQuery } from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

interface UpdateItemProps<UpdateItemInput> {
  action: string;
  query: UpdateItemGqlQuery;
  queryInput: UpdateItemInput;
}

const successQueryStatuses = ['updated', 'success'];

export function updateItem<UpdateItemInput, UpdateItemResponse>({
  action,
  query,
  queryInput
}: UpdateItemProps<UpdateItemInput>) {
  const { rawName, name } = getQueryName(query, 'mutation');

  productionConsole(
    'log',
    'updateItem',
    { action, queryInput },
    rawName,
    JSON.stringify(queryInput)
  );

  const requestId = generateUuid();

  return new Promise<UpdateItemResponse>((resolve, reject) => {
    request<UpdateItemResponse, UpdateItemInput>(
      `${GRAPHQL_API_URI}?mutationName=${name}&requestId=${requestId}`,
      query,
      queryInput,
      { requestId }
    )
      .then((response) => {
        if (includes(successQueryStatuses, get(response, `${action}.status`))) {
          return resolve(response);
        }

        reject(get(response, `${action}.errors`));
      })
      .catch((reason) => reject(reason));
  });
}
