export default {
  access: 'Access',
  action: 'Action',
  actionPlural: 'Actions',
  account: 'Account',
  activityDate: 'Activity date',
  additionalEmail1: 'Additional email #1',
  additionalEmail2: 'Additional email #2',
  additionalInformation: 'Additional Information',
  additionalInfo: 'Additional Info',
  additionalDiscount: 'Additional discount',
  address: 'Address',
  agreedHourlyRate: 'Agreed hourly rate',
  amount: 'Amount',
  android: 'Android',
  artist: 'Artist',
  archivizerTeamLocalTime: 'Archivizer team local time',
  applicationUacCgi: 'Application UAC CGI',
  airTableEmail: 'Airtable Email',
  airTablePassword: 'Airtable Password',
  airTableApiKey: 'Airtable API Key',
  airTableBaseId: 'Airtable Base ID',
  airTableTableName: 'Airtable Table Name',
  blocked: 'Blocked',
  bankDetails: 'Bank Details',
  bff: 'BFF',
  businessEmail: 'Business email',
  blockedDate: 'Blocked date',
  cell: 'Cell',
  cells: 'Cells',
  commission: 'Commission',
  coworking: 'Coworking',
  creditCommission: 'Credit commission',
  dateFormat: 'Date format',
  dateOfSettingTestProject: 'Date of setting test project',
  deleteAccount: 'Delete account',
  financeRole: 'Finance role',
  financeRoles: 'Finance roles',
  hostingCommission: 'Hosting commission',
  balance: 'Balance',
  burning: 'Burning',
  burningOnly: 'Burning only',
  category: 'Category',
  city: 'City',
  cdn: 'CDN',
  changePassword: 'Change password',
  coRevenueSharer: 'Co-revenue sharer',
  clientPrepayment: 'Client prepayment',
  clientStatus: 'Client status',
  clientManagers: 'Client managers',
  clientLocalTime: 'Client local time',
  coldSource: 'Cold source',
  colorTag: 'Color tag',
  confirmPassword: 'Confirm password',
  company: 'Company',
  companyName: 'Company name',
  companySize: 'Company size',
  companyStatus: 'Company status',
  companyCreator: 'Company creator',
  companyOwner: 'Company owner',
  companyCrm: 'Company CRM',
  companyRevenue: 'Company revenue',
  companyType: 'Company type',
  complexity: 'Complexity',
  competencies: 'Competencies',
  companyFinStatus: 'Company fin status',
  contactName: 'Contact name',
  contactPhone: 'Contact phone number',
  contactEmail: 'Contacts email address',
  receiver: 'Receiver',
  country: 'Country',
  created: 'Created',
  createdAt: 'Created at',
  createdDate: 'Created date',
  criticalRequirements: 'Critical requirements',
  currency: 'Currency',
  currentPassword: 'Current password',
  currencyExchangeRate: 'Currency exchange rate',
  debtLimit: 'Debt limit',
  defaultForNewClients: 'Default for new clients',
  defaultShare: 'Default share',
  defaultBillingAddress: 'Default billing address',
  defaultPayoneerAccount: 'Default Payoneer account',
  danger: 'Danger',
  deferredPaymentDays: 'Deferred payment (days)',
  description: 'Description',
  defaultProjectPrice: 'Default project price',
  defaultProjectBudgetLimit: 'Default project budget limit',
  defaultTaskPrice: 'Default task price',
  discount: 'Discount',
  expectedPaymentDate: 'Expected payment date',
  expectedPaymentAmount: 'Expected payment amount',
  expectedTime: 'Expected Work Time',
  estimateCost: 'Estimate cost',
  estimateDate: 'Estimate date',
  estimateAmount: 'Estimate amount',
  estimateSendingDate: ' Estimate sending date',
  estimatedByCm: 'Estimated by CM',
  estimatedWorkTime: 'Expected work time',
  exclusion: 'Exclusion',
  experience: 'Experience',
  facebook: 'Facebook',
  favorites: 'Favorites',
  financeTags: 'Finance tags',
  firstName: 'First Name',
  firstNameEn: 'First Name (EN)',
  firstNameUk: 'First Name (UK)',
  firstPaymentDate: 'First payment date',
  finFirstPaymentDate: 'Fin first payment date',
  finLastPaymentDate: 'Fin last payment date',
  filterToUpdate: 'Filter to update',
  imageUrl: 'Image URL',
  implementationDate: 'Due date',
  implementedAt: 'Implemented',
  industry: 'Industry',
  integration: 'Integration',
  invoiced: 'Invoiced',
  email: 'Email',
  emailAddress: 'Email address',
  filterName: 'Filter name',
  frontend: 'Frontend',
  fullName: 'Full Name',
  groups: 'Groups',
  project: 'Project',
  projectManager: 'Project Manager',
  projectManagers: 'Project managers',
  providedFullName: 'Provided Full Name',
  providedFullNameEn: 'Provided Full Name (En)',
  providedFullNameUk: 'Provided Full Name (Uk)',
  priority: 'Priority',
  id: 'Id',
  ios: 'iOS',
  isMentor: 'Is Mentor',
  interfaceLanguage: 'Interface language',
  itn: 'ITN',
  itemCategory: 'Item category',
  kind: 'Kind',
  lastActivityTime: 'Last activity time',
  lastActivity: 'Last activity',
  lastPaymentDate: 'Last payment date',
  label: 'Label',
  linkedIn: 'LinkedIn',
  instagram: 'Instagram',
  twitter: 'Twitter',
  leadCreationDate: 'Lead creation date',
  manualClientStatus: 'Manual client status ',
  manualStatus: 'Manual status',
  marketingSource: 'Marketing source',
  maxPrice: 'Max price',
  mentor: 'Mentor',
  mentors: 'Mentors',
  body: 'Body',
  bodyEn: 'Body (En)',
  bodyUk: 'Body (Uk)',
  minPrice: 'Min price',
  myProjects: 'My projects',
  myTasks: 'My tasks',
  name: 'Name',
  nameEn: 'Name (En)',
  nameUk: 'Name (Uk)',
  natureOfLead: 'Nature of lead',
  none: 'None',
  newFilterName: 'New filter name (optional)',
  notes: 'Notes',
  schoolAgreement: 'School agreement',
  ratio: 'Ratio',
  rating: 'Rating',
  surname: 'Surname',
  scopeOfWork: 'Scope of work',
  lastName: 'Last Name',
  salesManager: 'Sales manager',
  section: 'Section',
  systemAccount: 'System account',
  lastNameEn: 'Last Name (En)',
  lastNameUk: 'Last Name (Uk)',
  lastUpdate: 'Last update',
  nda: 'NDA',
  newPassword: 'New password',
  noGo: 'No Go',
  other: 'Other',
  operationHours: 'Operation hours (from)',
  partnershipAgreement: 'Partnership agreement',
  passport: 'Passport',
  paid: 'Paid',
  paymentType: 'Payment type',
  paymentMethods: 'Payment methods',
  payoneerIdLabel: 'Payoneer ID',
  payoneerIdGme: 'Payoneer ID (Individual) GME',
  payoneerIdPeGme: 'Payoneer ID (PE) GME',
  payoneerIdAvltd: 'Payoneer ID (Individual) AV Ltd',
  payoneerIdPeAvltd: 'Payoneer ID (PE) AV Ltd',
  password: 'Password',
  phone: 'Phone',
  position: 'Position',
  preferableCurrency: 'Preferable currency',
  prepayment: 'Prepayment',
  privateEntrepreneur: 'Private entrepreneur',
  price: 'Price',
  proficiencyLevel: 'Proficiency Level',
  profilePicture: 'Profile picture',
  qualifications: 'Qualifications',
  rankedName: 'Ranked name',
  rateEducationPoint: 'K edp',
  realDeclineReasons: 'Real decline reasons',
  received: 'Received',
  referer: 'Referer',
  role: 'Role',
  roles: 'Roles',
  requirements: 'Requirements',
  resolutionDpi: 'Resolution DPI',
  resolutionHeight: 'Resolution height',
  resolutionWidth: 'Resolution width',
  registrationDate: 'Registration date',
  source: 'Source',
  sourceFilesCount: 'Source files',
  specializations: 'Specializations',
  state: 'State',
  started: 'Started',
  startDate: 'Start date',
  storageOption: 'Storage option',
  status: 'Status',
  street: 'Street',
  subCategories: 'Subcategories',
  subject: 'Subject',
  tag: 'Tag',
  task: 'Task',
  tasksPrice: 'Tasks price',
  teamType: 'Team type',
  testProjectFinished: 'Test project finished',
  testProjectStarted: 'Test project started',
  testProjectCreatedAt: 'Date of setting Test Project',
  text: 'Text',
  title: 'Title',
  timeZone: 'Time zone',
  timeFormat: 'Time format',
  threeDServices: '3D Services',
  type: 'Type',
  totalBalance: 'Total balance',
  share: 'Share (%)',
  shares: 'Shares',
  uuid: 'UUID',
  nanoId: 'NanoID',
  team: 'Team',
  width: 'Width',
  height: 'Height',
  holdRate: 'Hold rate',
  dpi: 'DPI',
  fileFormat: 'File format',
  format: 'Format',
  hours: 'Hours',
  hoursMinutes: 'hours:minutes',
  minutes: 'Minutes',
  updatedAt: 'Updated at',
  user: 'User',
  useCustomRating: 'Use custom rating',
  updated: 'Updated',
  url: 'Url',
  unread: 'Unread',
  validateOutput: 'Validate output',
  vatNumber: 'VAT No',
  wig: 'WIG',
  workflowType: 'Workflow type',
  webSite: 'Website',
  zipCode: 'Zip code'
};
